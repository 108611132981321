import React from 'react';
import { useState, useEffect } from 'react';
import DatePicker, { registerLocale } from "react-datepicker";
import { zhCN, enGB, enUS } from "date-fns/locale";

import useLocale from '../Utilities/useLocale';

import "react-datepicker/dist/react-datepicker.css";
import "./DateTimePicker.css";

export interface DateTimePickerProps {
    name: string,
    dateTime: Date | null,
    dateOnly: boolean,
    isClearable: boolean,
    handleDateSelect: (date: Date | null) => void,
}

const DateTimePicker = ({ name, dateTime, handleDateSelect, isClearable=false, dateOnly=false}: DateTimePickerProps) => {
    registerLocale('zh-CN', zhCN);
    registerLocale('en-GB', enGB);
    registerLocale('en-US', enUS);

    const localeCode:string | undefined = useLocale();
    const [locale, setLocale] = useState(enGB);

    useEffect(() => {
        if (!localeCode) {
            return;
        }

        switch (localeCode) {
            case "zh-CN":
                setLocale(zhCN);
                break;
            case "en-US":
                setLocale(enUS);
                break;
            default:
                setLocale(enGB);
                break;
        }
    }, [localeCode]);

    return (
        <DatePicker name={name} selected={dateTime} onChange={(date:Date|null) => handleDateSelect(date)} showIcon toggleCalendarOnIconClick isClearable={isClearable} locale={locale} dateFormat={dateOnly ? "P" : "Pp"} timeFormat="p" showTimeSelect={!dateOnly} className="form-control" required timeIntervals={1} />
    )
}

export default DateTimePicker