import React, { useState, useEffect, Suspense } from 'react';
import { Route } from 'react-router';
import { Routes } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import NavMenu from './components/NavMenu';
import AlertBanner from './components/AlertBanner'
import LotList from './components/Pages/LotList';
import LotDetailsSelector from './components/Pages/LotDetails/LotDetailsSelector';
import UserPreferences from './components/Pages/UserPreferences';
import Reporting from './components/Pages/Reporting';
import ChangeLog from './components/Pages/ChangeLog';
import i18n from "i18next";
import Configuration from './components/Pages/Configuration';

import NotificationAlert from './components/Alerts/NotificationAlert';
import ProtectedRoute from './components/ProtectedRoute';
import Footer from './components/Footer';
import ConfigurationContext from './components/Contexts/ConfigurationContext';
import { AlertProvider } from './components/Contexts/AlertContext';
import { LotProvider } from './components/Contexts/LotContext';
import { useHttpUtils } from './components/Utilities/useHttpUtils';
import { useIdleTimer } from "react-idle-timer";
import { signOut } from './components/Utilities/AuthUtils';
import { useSiteCode } from './components/Utilities/useSiteCode';

import './custom.css';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { PrivilegeProvider } from './components/Contexts/PrivilegeContext';
import Admin from './components/Pages/Admin';

function LegacyApp(props) {

    const { t } = props;
    const { fetchDataAuthenticated } = useHttpUtils();

    const defaultIdleTimeout = 30;
    const [idleTimeout, setIdleTimeout] = useState(defaultIdleTimeout);
    useIdleTimer({
        onIdle: () => signOut(),
        timeout: 1000 * 60 * idleTimeout
    });

    const [configuration, setConfiguration] = useState({
        configurationData: []
    });

    const siteCode = useSiteCode();

    useEffect(() => {
        if (siteCode !== undefined) {
            console.log(`Changing translations for site namespace '${siteCode}'`);
            i18n.setDefaultNamespace(siteCode.toLowerCase());
            i18n.changeLanguage(i18n.language);
        }
    }, [siteCode]);

    useEffect(() => {
        fetchDataAuthenticated('api/v1/configurationdata/?includeDeleted=false')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {

                setConfiguration({
                    ...configuration,
                    configurationData: data
                });
            })
            .catch(error => {
                console.log("Failed to retrieve configuration data");
            });

        fetchDataAuthenticated('api/config/idletimeout')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setIdleTimeout(data);
            })
            .catch(error => {
                console.log(`Failed to get idle timeout from config. Setting default value '${defaultIdleTimeout}'`);
            });
    }, []);


    let titleSuffix = "";
    if (window.location.href.includes("dev")) {
        titleSuffix = "(Development)";
    } else if (window.location.href.includes("acc")) {
        titleSuffix = "(Acceptance)"
    }

    return (
        <ConfigurationContext.Provider value={configuration}>
            <HelmetProvider>
                <AlertProvider>
                    <PrivilegeProvider>
                        <LotProvider>
                            <Suspense fallback={<div>Loading...</div>}>
                                <NotificationAlert />
                                <AlertBanner variant={"danger"}/>
                                <NavMenu title={`${t("Application.Title")} ${titleSuffix}`} />
                                <Helmet>
                                    <title>{t("Application.TabTitle")}</title>
                                </Helmet>
                                <Routes>
                                    <Route path='/' exact element={<LotList />} />
                                    <Route path='/lots' exact element={<LotList />} />
                                    <Route path='/lots/ReadyToReview' exact element={<LotList status="ReadyToReview" tableName={"lots-table-ready-to-review"} />} />
                                    <Route path='/lots/ReadyToGrade' exact element={<LotList status="ReadyToGrade" tableName={"lots-table-ready-to-grade"} />} />
                                    <Route path='/lotdetails/:lotNumber' element={<LotDetailsSelector />} />
                                    <Route path='/config' exact element={<Configuration />} />
                                    <Route path='/config/businessunits' element={<Configuration initialSelectedTab={"businessUnits"} />} />
                                    <Route path='/config/configData' element={<Configuration initialSelectedTab={"configData"} />} />
                                    <Route path='/config/equipmenttypes' element={<Configuration initialSelectedTab={"equipmentTypes"} />} />
                                    <Route path='/config/equipment' element={<Configuration initialSelectedTab={"equipment"} />} />
                                    <Route path='/config/qualities' element={<Configuration initialSelectedTab={"qualities"} />} />
                                    <Route path='/config/lines' element={<Configuration initialSelectedTab={"lines"} />} />
                                    <Route path='/config/linequalitydata' element={<Configuration initialSelectedTab={"lineQualityData"} />} />
                                    <Route path='/config/labresultsconfig' element={<Configuration initialSelectedTab={"labResultsConfig"} />} />
                                    <Route path='/config/recipetypes' element={<Configuration initialSelectedTab={"recipeTypes"} />} />
                                    <Route path='/config/recipeproperties' element={<Configuration initialSelectedTab={"recipeProperties"} />} />
                                    <Route path='/config/recipedatatypes' element={<Configuration initialSelectedTab={"recipeDataTypes"} />} />
                                    <Route path='/config/recipedata' element={<Configuration initialSelectedTab={"recipeData"} />} />
                                    <Route path='/config/recipes' element={<Configuration initialSelectedTab={"recipes"} />} />
                                    <Route path='/config/gradedreasons' element={<Configuration initialSelectedTab={"gradedReasons"} />} />
                                    <Route path='/config/emails' element={<Configuration initialSelectedTab={"emails"} />} />
                                    <Route path='/config/packageTypes' element={<Configuration initialSelectedTab={"packageTypes"} />} />
                                    <Route path='/config/recipeToPackageTypes' element={<Configuration initialSelectedTab={"recipeToPackageTypes"} />} />
                                    <Route path='/config/changeLog' element={<ProtectedRoute privilege={"admin"} route={"/config"}><Configuration initialSelectedTab={"changeLog"} /></ProtectedRoute>} />
                                    <Route path='/admin' element={<ProtectedRoute privilege={"admin"}><Admin /></ProtectedRoute>} />
                                    <Route path='/user' element={<UserPreferences />} />
                                    <Route path='/reporting' element={<Reporting />} />
                                    <Route path='/changelog' element={<ChangeLog />} />
                                </Routes>
                                <Footer />
                            </Suspense>
                        </LotProvider>
                    </PrivilegeProvider>
                </AlertProvider>
            </HelmetProvider>
        </ConfigurationContext.Provider>
    );
}

const WrappedLegacyApp = withTranslation()(LegacyApp)

// i18n translations might still be loaded by the http backend
// use react's Suspense
export default function App() {
    return (
        <Suspense fallback="loading">
            <WrappedLegacyApp />
        </Suspense>
    );
}