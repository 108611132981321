import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';

import { useHttpUtils } from '../../Utilities/useHttpUtils';
import CommonConfigPage from './CommonConfigPage';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import DateColumn from '../../DataTable/datatable.datecolumn';
import { PackageTypeEditDialog } from '../../Dialogs/Configuration/PackageTypeEditDialog';

function PackageTypeTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(true);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getPackageTypes = async function () {
            let url = `api/v1/packageType/?includeDeleted=${includeDeleted}`;
            if (businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getPackageTypes();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Units Per Package',
            accessor: 'unitsPerPackage',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: 'Weight Per Unit',
            accessor: 'unitWeight',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {}});

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function PackageType() {
    const getNewPackageType = () => {
        return {id:null, name:"", unitsPerPackage:0, unitWeight:0}
    }

    return (
        <CommonConfigPage
            title={"Package Type"}
            table={(includeDeleted, showEditDialog, refreshList) => <PackageTypeTable includeDeleted={includeDeleted} refresh={refreshList} onRowClick={showEditDialog} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <PackageTypeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewPackageType}
        >
        </CommonConfigPage>
    );
}

export default PackageType;