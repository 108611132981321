import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { Form, InputGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { useTranslationUtils } from '../Utilities/TranslationUtils';

function GlobalFilter({ preGlobalFilteredRows, globalFilter, setGlobalFilter, searchIcon }) {

    const { tp } = useTranslationUtils();

    const [value, setValue] = useState(globalFilter);
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined)
    }, 200);

    const helpText = preGlobalFilteredRows ? tp("Search.PlaceHolderWithCount", { length: preGlobalFilteredRows.length }) : tp("Search.PlaceHolder");

    return (
        <InputGroup>
            {searchIcon &&
                <InputGroup.Text><FontAwesomeIcon icon={faSearch} /></InputGroup.Text>
            }
            <Form.Control size="sm" id="globalFilter" value={value || ""}
                title={helpText}
                onChange={e => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }} />
        </InputGroup>
    )
}

export default GlobalFilter;