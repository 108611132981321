import React, { useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../../Dialogs/DraggableModalDialog';
import CommentDialog from '../../Dialogs/CommentDialog';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import useAlert from '../../Alerts/useAlert';

function CancelLotSplitDialog({ showDialog, closeDialog, lotNumber, getLotDetail }) {

    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();

    const [loading, setLoading] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [disableCancel, setDisableCancel] = useState(false);

    const { setAlert } = useAlert();

    // Cancel the lot split
    const cancelSplitLot = async (comment) => {
        setLoading(true);
        setDisableCancel(true);

        await fetchDataAuthenticated(`api/v1/lot/${lotNumber}/split/cancel`, "POST", JSON.stringify(comment))
            .then(async response => {

                if (response.ok) {
                    setAlert("success", "Success", "Lot split was cancelled")
                    getLotDetail();
                    closeDialogAndResetForm();
                } else {
                    throw new Error("Failed to cancel lot split");
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
                setLoading(false);
                setDisableCancel(false);
            });
    }

    const closeDialogAndResetForm = () => {
        setLoading(false);
        setDisableCancel(false);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialogAndResetForm} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Dialogs.CancelLotSplit.Title")} {lotNumber}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading &&
                        <div className="d-flex justify-content-center mb-2">
                            <Spinner animation="border" />
                        </div>
                    }
                    {!loading &&
                        <p>{t("Dialogs.CancelLotSplit.AreYouSure")}</p>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialogAndResetForm}>
                        {t("Application.Controls.Close")}
                    </Button>
                    <Button variant="primary" type="submit" onClick={() => setShowCommentDialog(true)} disabled={disableCancel}>
                        {t("Dialogs.CancelLotSplit.Cancel")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={cancelSplitLot} />
        </>
    );
}

export default CancelLotSplitDialog;