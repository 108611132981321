import React, { useState, useContext } from 'react';
import { Button, Modal } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import { DraggableModalDialog } from '../DraggableModalDialog';
import CommentDialog from '../CommentDialog';
import PrivilegeContext from '../../../components/Contexts/PrivilegeContext';
import { useTranslation } from 'react-i18next';
import { usePrivileges } from '../../Auth/usePrivileges';

function CommonConfigDialog({
    children,
    title,
    dialogData,
    showDialog,
    closeDialog,
    save,
    fieldErrors,
    setFieldErrors,
    deleteFunction,
    wideDialog = false,
    formId = "configForm",
    requireComment = true,
    saveTranslationKey = "Configuration.Dialogs.Common.Save" },
    canDelete = false)
{
    const { t } = useTranslation();
    const { admin } = usePrivileges();

    const [initial] = useState({ requireComment });
    const [validated, setValidated] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);

    const { canConfigure } = useContext(PrivilegeContext);

    const handleClose = () => {
        setFieldErrors({});
        setValidated(false);
        closeDialog();
    }

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            if (initial.requireComment) {
                setShowCommentDialog(true);
            } else {
                saveDialogData();
            }
        }

        setValidated(true);
    }

    const handleCommentSave = (comment) => {

        dialogData.comment = comment

        saveDialogData();
    }

    const handleDelete = () => {
        if (canDelete) {
            deleteFunction(dialogData)
                .then(data => {
                    setFieldErrors({});
                    closeDialog();
                })
                .catch(error => {
                    setFieldErrors({
                        ...fieldErrors,
                        ...error.errors
                    });

                    for (const field in error.errors) {
                        let fieldDOM = document.querySelector("[name=" + field + "]");
                        fieldDOM.setCustomValidity(error.errors[field]);
                    }
                })
        }
    }

    const saveDialogData = () => {
        save(dialogData)
            .then(data => {
                setFieldErrors({});
                closeDialog();
            })
            .catch(error => {
                setFieldErrors({
                    ...fieldErrors,
                    ...error.errors
                });

                for (const field in error.errors) {
                    let fieldDOM = document.querySelector("[name=" + field + "]");
                    fieldDOM.setCustomValidity(error.errors[field]);
                }
            });
    }

    return (
        <>
            {showDialog &&
                <Modal dialogAs={DraggableModalDialog} className={wideDialog ? "wide-dialog" : ""} show={showDialog} onHide={handleClose} backdrop="static">
                    <Modal.Header closeButton>
                        <Modal.Title>{title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form noValidate validated={validated} onSubmit={handleSave} id={formId}>
                            <fieldset disabled={!canConfigure}>
                                {children}
                            </fieldset>
                        </Form>
                    </Modal.Body>
                    <Modal.Footer>
                        {canDelete &&
                            admin &&
                            !!dialogData.id &&
                            <Button variant="danger" onClick={handleDelete} className="me-auto">
                                Delete
                            </Button>
                        }
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        {save &&
                            <Button variant="primary" type="submit" form={formId} disabled={!canConfigure}>
                                {t(saveTranslationKey)}
                            </Button>
                        }
                    </Modal.Footer>
                </Modal>
            }

            {showCommentDialog &&
                <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleCommentSave} />
            }
        </>
    );
}

export default CommonConfigDialog;