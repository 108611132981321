import React from 'react';
import { useDateToLocaleString, longDateAndTimeFormat } from '../../../Utilities/DateUtils';
import { useTranslation } from 'react-i18next';
import CommentsTable from './CommentsTable';
import ReasonFilter from './CommentsReasonFilter';

function CommentsSection({ comments }) {
    const { t } = useTranslation();

    const tableColumns = [
        {
            Header: t('LotDetails.Comments.CommentDate'),
            accessor: 'commentDate',
            Cell: (props) => {
                return useDateToLocaleString(props.value, longDateAndTimeFormat)
            }
        },
        {
            Header: t('LotDetails.Comments.Account'),
            accessor: 'account'
        },
        {
            Header: t('LotDetails.Comments.Reason'),
            accessor: 'reason',
            Filter: ReasonFilter,
            filter: "jsonArray"
        },
        {
            Header: t('LotDetails.Comments.Comment'),
            accessor: 'commentText',
            Cell: (props) => {
                return <p className="text-break">{props.value}</p>
            }
        }
    ];

    const initialState = {
        sortBy: [
            {
                id: 'commentDate',
                desc: true
            }
        ]
    };

    let table = <CommentsTable columns={tableColumns} data={comments} initialState={initialState} />

    return comments?.length > 0 ? table : "None";
}

export default CommentsSection;