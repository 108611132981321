import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Row } from 'react-table';
import { useTranslation } from 'react-i18next';

import { Quality } from '../../../../Types/Quality';

import './QualitiesSection.css';

export interface UsedQualityValueSelectCellProps {
    row: Row<Quality>,
    qualities: Quality[],
    setQualities: Dispatch<SetStateAction<Quality[]>>,
    canEdit: boolean,
}

function UsedQualityValueSelectCell({ row, qualities, setQualities, canEdit }: UsedQualityValueSelectCellProps): React.JSX.Element {
    const { t } = useTranslation();
    const quality = qualities[row.index]; 
    const intialValue = quality.qualityValues.find((qv) => !qv.status.includes("Unused"))?.testMethod ?? "";
    const [value, setValue] = useState(intialValue);

    const alwaysIncludeMethods = ["LBMLABCALC"];
    const neverIncludeMethods = ["PPK"];
    const options = quality.qualityValues
        .filter((qv) => !neverIncludeMethods.includes(qv.testMethod)
            && (alwaysIncludeMethods.includes(qv.testMethod)
                || qv.testMethod === intialValue
                || qv.resultValue !== ""))
        .map(qv => <option key={`${quality.name}-value-option-${qv.testMethod}`} value={qv.testMethod}>
            {t(`LotDetails.Qualities.${qv.testMethod}`).toString()}
        </option>);

    const saveChanges = () => {
        if (intialValue === value) {
            return;
        }

        quality.qualityValues = quality.qualityValues.map((qv) => {
            if (qv.testMethod === value) {
                var newStatus = qv.status.replace(" Unused", "");
                return { ...qv, status: newStatus, graderSelected: true };
            }

            if (qv.testMethod !== value && !qv.status.includes("Unused")) {
                return { ...qv, status: qv.status + " Unused", graderSelected: false };
            }

            return qv;
        });
        setQualities([...qualities]);
    }

    return (
        <Form.Select name={`${quality.name}-used-value-select`} className="used-value-select" value={value} onChange={(e) => setValue(e.target.value)} onBlur={saveChanges} disabled={!canEdit} >
            {options}
        </Form.Select>
    );
}

export default UsedQualityValueSelectCell;