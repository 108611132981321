import React from 'react';
import { Column, UseFiltersColumnOptions } from 'react-table';

import { Quality } from '../../../../Types/Quality';
import NoFilter from '../../../../DataTable/datatable.nofilter';
import LBMLabCell from './LBMLabCell';

export interface LBMLabColumnProps {
    header: string,
    qualityType:string,
}
function LBMLabColumn({ header, qualityType }: LBMLabColumnProps): Column<Quality> & UseFiltersColumnOptions<Quality> {
    return {
        Header: header,
        id: header,
        accessor: "qualityValues",
        Cell: (props) => <LBMLabCell row={props.row} qualityName={props.row.original.name} qualityType={qualityType} />,
        Filter: NoFilter
    }
}

export default LBMLabColumn;