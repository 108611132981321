import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import ConfigurationDataEditDialog from '../../Dialogs/Configuration/ConfigurationDataEditDialog';
import { Nav, Form } from 'react-bootstrap';
import { useBusinessUnits } from '../../Utilities/Hooks';
import CommonConfigPage from './CommonConfigPage';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function ConfigurationDataTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(true);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getConfigurationData = async function () {
            let url = `api/v1/configurationdata/?includeDeleted=${includeDeleted}`;

            if (businessUnitId === -1 || businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getConfigurationData();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Value',
            accessor: 'value',
            filter: 'includesString'
        },
        {
            Header: 'Description',
            accessor: 'description',
            filter: 'includesString'
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    const initialState = {
        sortBy: [
            {
                id: 'name',
                desc: false
            },
            {
                id: 'businessUnit',
                desc: false
            }
        ]
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: initialState, getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function ConfigurationDataConfiguration() {

    const businessUnits = useBusinessUnits(false);
    const [businessUnitId, setBusinessUnitId] = useState();

    const getNewConfigurationData = () => {
        return { configurationDataId: null, name: "", value: "", description: "", businessUnitId: businessUnitId, isDeleted: false }
    };

    return (
        <CommonConfigPage
            title="Configuration Data"
            table={(includeDeleted, showEditDialog, refreshList) => <ConfigurationDataTable onRowClick={showEditDialog} includeDeleted={includeDeleted} businessUnitId={businessUnitId} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <ConfigurationDataEditDialog  dialogData = { dialogData } setDialogData = { setDialogData } showDialog = { showDialog } closeDialog = { closeDialog } businessUnits = { businessUnits } />}
            getNewConfigObject={getNewConfigurationData}
        >
            <Form.Select type="text" value={businessUnitId} className="filter-select float-start" onChange={(e) => { setBusinessUnitId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Business Units</option>
                <option value={-1} key={-1}>Site Level</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </Form.Select>
        </CommonConfigPage>
    );
}

export default ConfigurationDataConfiguration;