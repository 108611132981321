import React from 'react';
import { useTranslation } from 'react-i18next';
import { Container, Accordion } from 'react-bootstrap';
import { faTriangleExclamation, faComment, faMessage, faMagnifyingGlass, faNoteSticky, faTimeline, faFlask } from '@fortawesome/free-solid-svg-icons';

import LotDetailSummary from './LotDetailsComponents/Summary';
import WarningsSection from './LotDetailsComponents/WarningsSection';
import GradingCommentsSection from './LotDetailsComponents/GradingCommentsSection';
import CommentsSection from './LotDetailsComponents/CommentsSection';
import QualitiesSection from './LotDetailsComponents/QualitiesSection_HCC';
import GenealogySection from './LotDetailsComponents/GenealogySection';
import QIRSection from './LotDetailsComponents/QIRSection_QIRMS';
import LabResultsSection from './LotDetailsComponents/LabResultsSection_HCC';
import { LotDetailSection } from './LotDetailSection';
import { SectionNavigation } from './SectionNavigation_HCC';

function LotDetails({ lotDetail, getLotDetail, setLotDetail, loading, setLoading, hideLotSplit, hideFullRecalc }) {

    const { t } = useTranslation();

    const getAccordionHeaderClass = (title) => {
        let warningClassName = "warning";

        switch (title) {
            case "warnings":
                return lotDetail.warnings.length > 0 ? warningClassName : "";
            case "qualities":
                return lotDetail.qualities.some(q => q.isOffspec) ? warningClassName : "";
            case "labresults":
                return lotDetail.labResults.some(l => l.isOffspec) ? warningClassName : "";
            case "qirs":
                return lotDetail.qirs.length > 0 ? warningClassName : "";
            default:
                return "";
        }
    }

    const lotSummary = {
        lotNumber: lotDetail.summary.lotNumber,
        summary: lotDetail.summary,
        revisions: lotDetail.revisions,
        getLotDetail: getLotDetail,
        setLoading: setLoading,
        warnings: lotDetail.warnings,
        comments: lotDetail.comments,
        hideLotSplit: hideLotSplit,
        hideFullRecalc: hideFullRecalc
    } 

    const sections = [
        { key: 'warnings', title: t('LotDetails.Warnings.Title'), icon: faTriangleExclamation, component: <WarningsSection warnings={lotDetail.warnings} lotNumber={lotDetail.summary.lotNumber} getLotDetail={getLotDetail} businessUnitCode={lotDetail.summary.businessUnitCode} /> },
        { key: 'qirs', title: t('LotDetails.QIRs.Title'), icon: faNoteSticky, component: <QIRSection qirs={lotDetail.qirs} /> },
        { key: 'qualities', title: t('LotDetails.Qualities.Title'), icon: faMagnifyingGlass, component: <QualitiesSection intialQualities={lotDetail.qualities} businessUnitCode={lotDetail.summary.businessUnitCode} lotNumber={lotDetail.summary.lotNumber}lotDetail={lotDetail} setLotDetail={setLotDetail} />},
        { key: 'labresults', title: `${t('LotDetails.LabResults.Title')} (${lotDetail.labResults.length})`, icon: faFlask, component: <LabResultsSection labResults={lotDetail.labResults} /> },
        { key: 'genealogy', title: t('LotDetails.Genealogy.Title'), icon: faTimeline, component: <GenealogySection genealogy={lotDetail.genealogy} lotNumber={lotDetail.summary.lotNumber} /> },
        { key: 'gradingcomments', title: t('LotDetails.GradingComments.Title'), icon: faComment, component: <GradingCommentsSection gradingComments={lotDetail.gradingComments} lotNumber={lotDetail.summary.lotNumber} getLotDetail={getLotDetail} currentRevision={lotDetail.summary.lotRevisionId} /> },
        { key: 'comments', title: t('LotDetails.Comments.Title'), icon: faMessage, component: <CommentsSection comments={lotDetail.comments} /> },
    ]

    return (
        <>
            <Container className={loading ? "fade-content" : ""}>
                {lotDetail &&
                    <>
                        <LotDetailSummary {...lotSummary} />

                        <SectionNavigation sections={sections} />

                        <Accordion defaultActiveKey={sections.map(section=>section.key)} alwaysOpen className="mb-2">
                            {sections.map(section =>
                                <LotDetailSection
                                    key={section.key}
                                    name={section.key}
                                    className={`${getAccordionHeaderClass(section.key)}`}
                                    icon={section.icon}
                                    title={section.title}
                                    component={section.component} />)
                            }
                        </Accordion>
                    </>
                }
            </Container>
        </>
    )
}

export default LotDetails;