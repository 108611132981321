import React, { useEffect } from "react";
import { Row, Col, Form, Spinner } from 'react-bootstrap';
import { useGetSplittableBatches } from '../../Utilities/Hooks.js';
import { useTranslation } from 'react-i18next';

function LotSplitByBatchBuilder({ lotSplitBatches, setLotSplitBatches, updatedSplit, lotNumber, currentGrade, alternativeGrades, allGrades }) {

    const { t } = useTranslation();
    const { splittableBatches, loading } = useGetSplittableBatches(lotNumber);

    useEffect(() => {
        splittableBatches.sort();
        let tempLotSplitBatches = splittableBatches.map((batch, idx) => {
            return {batch:batch, suffix:"A", grade: currentGrade}
        })

        setLotSplitBatches(tempLotSplitBatches);

    }, [splittableBatches, setLotSplitBatches, currentGrade]);

    const handleUpdateRow = (e) => {

        let index = e.target.dataset.id;
        let prop = e.target.name;
        let value = e.target.value;

        let tempLotSplitBatches = [...lotSplitBatches];
        let tempLotSplitBatch = { ...tempLotSplitBatches[index] };

        tempLotSplitBatch[prop] = value;
        tempLotSplitBatches[index] = tempLotSplitBatch;

        setLotSplitBatches(tempLotSplitBatches);

        updatedSplit();
    }

    if (loading) {
        return (
            <div className="d-flex align-items-start me-auto">
                <Spinner animation="border" size="md" className="me-3" />
                <p className="mt-2">{t("LotSplit.LoadingBatches")}</p>
            </div>
        );
    }
    else if (lotSplitBatches.length === 0) {
        return (
            <Row className="mb-3">
                <Col sm={6}>{t("LotSplit.NoSplittableBatchesMessage")}</Col>
            </Row>
        );
    }
    else {
        return (
            lotSplitBatches.map((lotSplitBatch, idx) => {

                let suffix = `suffix-${idx}`;
                let grade = `grade-${idx}`;

                return (
                    <Row key={idx} className="mb-3">
                        <Col sm={3}>
                            {idx === 0 &&
                                <p>{t("LotSplit.Batch")}</p>
                            }
                            <Form.Group controlId={suffix}>
                                <Form.Control type="text" placeholder="Batch"
                                    name="suffix"
                                    data-id={idx}
                                    readOnly
                                    value={lotSplitBatch.batch}>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={2}>
                            {idx === 0 &&
                                <p>{t("LotSplit.Suffix")}</p>
                            }
                            <Form.Group controlId={suffix}>
                                <Form.Control type="text" placeholder="Suffix"
                                    name="suffix"
                                    data-id={idx}
                                    required
                                    value={lotSplitBatch.suffix}
                                    maxLength={1}
                                    onChange={handleUpdateRow}>
                                </Form.Control>
                            </Form.Group>
                        </Col>
                        <Col sm={3}>
                            {idx === 0 &&
                                <p>{t("LotSplit.Grade")}</p>
                            }
                            <Form.Group controlId={grade}>
                                <Form.Select
                                    aria-label="Select grade"
                                    name="grade"
                                    data-id={idx}
                                    required
                                    value={lotSplitBatch.grade}
                                    onChange={handleUpdateRow}>
                                    <option value="">{t("Application.Controls.DefaultSelect")}</option>
                                    <option value={currentGrade}>{currentGrade}</option>
                                    {alternativeGrades.map((grade, index) =>
                                        <option key={`grade-${index}`} value={grade.grade}>{grade.grade}</option>
                                    )}
                                    <option value="" disabled>------------</option>
                                    {allGrades.map((grade, index) =>
                                        <option key={`grade-${alternativeGrades.length + 1 + index}`} value={grade}>{grade}</option>
                                    )}
                                </Form.Select>
                            </Form.Group>
                        </Col>
                    </Row>
                );
            })
        );
    }
}

export default LotSplitByBatchBuilder