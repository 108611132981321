import { QualityValue } from "../../../../Types/Quality";

function GetQualityColumnClass(qualityValue:QualityValue): string {
    let className = "";

    if (!qualityValue.status.includes("Unused")) {
        className = "selected";
    }
    else if (qualityValue.isDefault) {
        className = "default-value";
    }

    if (qualityValue.graderSelected) {
        className += " fst-italic";
    }

    return className;
}

export default GetQualityColumnClass;