import { useEffect, useState } from 'react';
import { Button, Row, Col, Form } from 'react-bootstrap';

import { useHttpUtils } from '../../Utilities/useHttpUtils'
import useAlert from '../../Alerts/useAlert';

const GetSiteAlert = ({ setVariant }) => {
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();
    const [siteAlert, setSiteAlert] = useState("");
    const [formAlert, setFormAlert] = useState("");
    const [error, setError] = useState();

    useEffect(() => {
        getAlert();
    }, []);

    const getAlert = () => {
        fetchDataAuthenticated("api/v1/users/siteAlert", "GET")
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => Promise.reject(error));
                }
                return response.json();
            })
            .then(data => {
                setVariant("");
                setSiteAlert(data);
                setError(null);
            }).catch(error => {
                setVariant("danger");
                setAlert("danger", error.title, error.detail)
                setSiteAlert();
                setError(error.detail);
            });
    }


    const updateAlert = () => {
        fetchDataAuthenticated("api/v1/admin/siteAlert", "PUT", `"${formAlert}"`, setSiteAlert, setAlert);
    }

    return (
        <>
            <Row>
                <Col>
                    { error ? error : siteAlert || "No Alert Found" }
                </Col>
            </Row>
            <Row className="mt-2 mb-2">
                <Form>
                    <Form.Group>
                        <Form.Label><strong>Set Alert</strong></Form.Label>
                        <Form.Control variant="text" value={formAlert} onChange={(e) => setFormAlert(e.target.value) } placeholder="Enter new alert" />
                    </Form.Group>
                </Form>
            </Row>
            <Button type="submit" className="ms-auto mt-auto" onClick={updateAlert}>Set</Button>
        </>

    )
}

export default GetSiteAlert;