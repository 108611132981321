import React, { useState } from 'react';
import { Button, Modal, Form, Spinner } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../Dialogs/DraggableModalDialog';
import CommentDialog from '../Dialogs/CommentDialog';
import { useHttpUtils } from '../Utilities/useHttpUtils';
import useAlert from '../Alerts/useAlert';
import { useNavigate } from 'react-router-dom';

function RenameLotDialog({ showDialog, closeDialog, lotNumber }) {

    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();

    const [newLotNumber, setNewLotNumber] = useState();
    const [loading, setLoading] = useState(false);

    const [formValidated, setFormValidated] = useState(false);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const { setAlert } = useAlert();
    const navigate = useNavigate();

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        const form = e.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        }

        setFormValidated(true);
    };

    const renameLotWithComment = async (comment) => {

        setLoading(true);

        let body = {
            newLotNumber: newLotNumber.trim(),
            comment: comment
        };

        fetchDataAuthenticated(`api/v1/lot/lotNumber/${lotNumber}`, "PUT", JSON.stringify(body))
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Success", "Lot was renamed successfully");
                    navigate('/lotdetails/' + newLotNumber.trim());
                    closeDialogAndResetForm();
                } else if (response.status === 403) {
                    throw new Error("You do not have permission to rename lot");
                } else {
                    throw new Error("Failed to rename lot");
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    const closeDialogAndResetForm = () => {
        setFormValidated(false);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialogAndResetForm} backdrop="static">
                <Modal.Header closeButton>
                    <Modal.Title>{t("Dialogs.RenameLot.Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {loading &&
                        <div className="d-flex justify-content-center">
                            <Spinner animation="border" />
                        </div>
                    }
                    {!loading &&
                        <Form id="renameLot" noValidate validated={formValidated} onSubmit={handleSave}>

                            <Form.Group className="mb-3" controlId="newLotNumber">
                                <Form.Label>{t("Dialogs.RenameLot.NewLotNumber")}: </Form.Label>
                                <Form.Control required
                                    onChange={(e) => setNewLotNumber(e.target.value)}>
                                </Form.Control>
                            </Form.Group>
                        </Form>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialogAndResetForm}>
                        {t("Application.Controls.Close")}
                    </Button>
                    <Button variant="primary" type="submit" form="renameLot">
                        {t("Application.Controls.Save")}
                    </Button>
                </Modal.Footer>
            </Modal>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={renameLotWithComment} />
        </>
    );
}

export default RenameLotDialog;