import React from 'react'
import { useTranslation } from 'react-i18next';
import { Column, UseFiltersColumnOptions } from 'react-table';

import NoFilter from '../../../../DataTable/datatable.nofilter';
import { Quality } from '../../../../Types/Quality';

function ClassificationColumn(): Column<Quality> & UseFiltersColumnOptions<Quality> {
    const { t } = useTranslation();

    // Find classification off the as-added result
    return {
        Header: t('LotDetails.Qualities.Classification').toString(),
        accessor: 'classificationOffspec',
        Cell: (props) => {
            let asadded = props.row.original.qualityValues.find((qv) => qv.testMethod === "ASADDED");
            return <>{!!(asadded?.classification) ? asadded.classification : ""}</>;
        },
        Filter: NoFilter
    }
}

export default ClassificationColumn;