import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, RequiredDialogInput, RequiredDialogSelect, DialogSelect, DialogCheckbox } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function RecipePropertyEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const [fieldErrors, setFieldErrors] = useState({});

    const saveRecipeProperty = (recipeProperty) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/recipeproperty`;
            if (recipeProperty.recipePropertyId != null) {
                url += `/${recipeProperty.recipePropertyId}`
            }
            
            fetchDataAuthenticated(url, "POST", JSON.stringify(recipeProperty))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }
    
    return (
        <CommonConfigDialog title={"Recipe Property - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveRecipeProperty} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors}>
            <RequiredDialogInput controlId="formRecipePropertyName" title="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogInput controlId="formRecipePropertyDescription" title="Description" name="description" value={dialogData.description} error={fieldErrors.description} onChange={onChange} />
            <DialogSelect controlId="formRecipePropertyDataType" title="Data Type" name="dataType" value={dialogData.dataType} error={fieldErrors.dataType} onChange={onChange}>
                <option value="" key="0">Please Select...</option>
                <option value="boolean" key="1">boolean</option>
                <option value="double" key="2">double</option>
                <option value="int" key="3">int</option>
            </DialogSelect>
            <RequiredDialogSelect controlId="formRecipePropertyBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <DialogCheckbox disabled={dialogData.recipePropertyId === null} controlId="formRecipePropertyIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default RecipePropertyEditDialog;