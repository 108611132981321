import React, { useEffect, useContext } from 'react';
import { useTranslation } from "react-i18next";
import { Badge, Container, Nav, Navbar } from 'react-bootstrap';
import { Link, NavLink } from 'react-router-dom';
import { useInterval } from 'usehooks-ts';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCog, faUser, faScrewdriverWrench } from '@fortawesome/free-solid-svg-icons';

import LotContext from './Contexts/LotContext';
import { client as publicClientApplication, signOut } from './Utilities/AuthUtils';
import PrivilegeContext from './Contexts/PrivilegeContext';

import logo from '../images/cmn-exxonmobil-logo.gif';
import './NavMenu.css';

export default function NavMenu(props) {

    const { t } = useTranslation();

    const lotContext = useContext(LotContext);
    const { admin } = useContext(PrivilegeContext);

    const account = publicClientApplication.getAllAccounts()[0];

    useEffect(() => {
        lotContext.updateCounters();
    }, []);

    // update lot counters at interval
    useInterval(async () => {
        lotContext.updateCounters();
    }, 30000);

    return (
        <header>
            <Navbar expand="lg" bg="white" variant="light" className="border-bottom box-shadow mb-3">
                <Container fluid>
                    <Navbar.Brand as={Link} to="/" className="me-3">
                        <img src={logo}
                            alt="ExxonMobil"
                            width="150"
                            className="d-inline-block align-middle me-3" />
                        <strong className="d-none d-xl-inline-block">{props.title}</strong>
                        <strong className="d-xl-none">{t("Application.Abbreviation")}</strong>
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav.Link as={NavLink} to="/lots/ReadyToReview" >
                            <Navbar.Text className="text-dark me-2">{t("Navigation.AwaitingReview")}</Navbar.Text>
                            {lotContext.readyToReviewCount > 0 &&
                                <Badge pill bg="danger">{lotContext.readyToReviewCount}</Badge>
                            }
                        </Nav.Link>
                        <Nav.Link as={NavLink} to="/lots/ReadyToGrade" >
                            <Navbar.Text className="text-dark me-2">{t("Navigation.AwaitingGrading")}</Navbar.Text>
                            {lotContext.readyToGradeCount > 0 &&
                                <Badge pill bg="danger">{lotContext.readyToGradeCount}</Badge>
                            }
                        </Nav.Link>
                        {/*TODO: Should there be a permission on this new link to a reporting page?*/}
                        <Nav.Link as={NavLink} to="/reporting" >
                            <Navbar.Text className="text-dark me-2">{t("Navigation.Reporting")}</Navbar.Text>
                        </Nav.Link>
                    </Navbar.Collapse>
                    <Navbar.Collapse className="justify-content-end">
                        <Nav.Link as={NavLink} to="config">
                            <FontAwesomeIcon icon={faCog} className="me-2" />
                            <Navbar.Text className="text-dark">{t("Navigation.Configuration")}</Navbar.Text>
                        </Nav.Link>
                        {admin && 
                        <Nav.Link as={NavLink} to="admin">
                            <FontAwesomeIcon icon={faScrewdriverWrench} className="me-2" />
                            <Navbar.Text className="text-dark">{t("Navigation.Admin")}</Navbar.Text>
                        </Nav.Link>
                        }
                        <Nav.Link as={NavLink} to="user">
                            <FontAwesomeIcon icon={faUser} className="me-2" />
                            <Navbar.Text className="text-dark">{account && account.name}</Navbar.Text>
                        </Nav.Link>
                        <Navbar.Text>
                            <Nav.Link onClick={signOut}>{t("Navigation.SignOut")}</Nav.Link>
                        </Navbar.Text>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </header>
    )
}