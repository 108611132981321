import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import DateColumn from '../../DataTable/datatable.datecolumn';
import { Nav } from 'react-bootstrap';
import CommonConfigPage from './CommonConfigPage';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import EmailEditDialog from '../../Dialogs/Configuration/EmailEditDialog';

function EmailsTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);
    useEffect(() => {
        const getEmails = async function () {
            let url = `api/v1/email`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getEmails();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Subject',
            accessor: 'subject',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'To',
            accessor: 'to',
            filter: 'includesString',
        },
        {
            Header: 'Content Type',
            accessor: 'contentType',
            Filter: SelectFilter,
            filter: 'equals'
        },
        DateColumn({ header: 'Generated At', accessor: 'generatedAtLocalTime' }),
        DateColumn({ header: 'Last Sent At', accessor: 'sentAtLocalTime' }),
        {
            Header: 'Send Attempts',
            accessor: 'sendAttempts',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {}, getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function EmailConfiguration() {
    return (
        <CommonConfigPage
            title={"Emails"}
            table={(includeDeleted, showEditDialog, refreshList) => <EmailsTable includeDeleted={includeDeleted} refresh={refreshList} onRowClick={showEditDialog} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <EmailEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
        >
        </CommonConfigPage>
    );
}

export default EmailConfiguration;