import React, { useState, useEffect } from 'react';
import { Nav } from 'react-bootstrap';

import { useHttpUtils } from '../../Utilities/useHttpUtils';
import CommonConfigPage from './CommonConfigPage';
import DataTable from '../../DataTable/datatable.component';
import { RecipeToPackageTypeEditDialog } from '../../Dialogs/Configuration/RecipeToPackageTypeEditDialog';

function RecipeToPackageTypeTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(true);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getPackageTypes = async function () {
            let url = `api/v1/recipeToPackageType/?includeDeleted=${includeDeleted}`;
            if (businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getPackageTypes();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Material Code',
            accessor: 'materialCode',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Recipe',
            accessor: 'recipeName',
            filter: 'includesString',
        },
        {
            Header: 'Package Type',
            accessor: 'packageTypeName',
            filter: 'includesString',
        },
        {
            Header: 'Is Default',
            accessor: 'isDefault',
            filter: 'includesString',
            Cell: (props) => <div>{props.value ? "Yes" : ""}</div>
        },

    ];

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {} });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}


function RecipeToPackageType() {
    const getNewRecipeToPackageType = () => {
        return {id:null, materialCode:"", recipeId:0, packageTypeId:0}
    }

    return (
        <CommonConfigPage
            title={"Recipe To Package Type"}
            table={(includeDeleted, showEditDialog, refreshList) => <RecipeToPackageTypeTable includeDeleted={includeDeleted} refresh={refreshList} onRowClick={showEditDialog} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <RecipeToPackageTypeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />}
            getNewConfigObject={getNewRecipeToPackageType}
        >
        </CommonConfigPage>
    );
}

export default RecipeToPackageType;