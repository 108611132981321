import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, RequiredDialogInput, RequiredDialogSelect, DialogCheckbox } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import { useTranslation } from "react-i18next";

function GradedReasonEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();

    const [fieldErrors, setFieldErrors] = useState({});

    const saveGradedReason = (gradedReason) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/gradedreason`;
            if (gradedReason.gradedReasonId != null) {
                url += `/${gradedReason.gradedReasonId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(gradedReason))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={t("Configuration.Dialogs.GradedReasonTitle")} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveGradedReason} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors}>
            <RequiredDialogInput controlId="formGradedReasonCode" title="Reason code" name="reasonCode" value={dialogData.reasonCode} error={fieldErrors.reasonCode} onChange={onChange} />
            <DialogInput controlId="formGradedReasonDescription" title="Description" name="description" value={dialogData.description} error={fieldErrors.description} onChange={onChange} />
            <RequiredDialogSelect controlId="formGradedReasonBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >{t("Application.Controls.DefaultSelect")}...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <DialogInput controlId="formGradedReasonTranslation" title="Translation" name="translation" value={dialogData.translation} error={fieldErrors.translation} onChange={onChange} />
            <RequiredDialogInput controlId="formGradedReasonDisplayOrder" title="Display Order" name="displayOrder" value={dialogData.displayOrder} error={fieldErrors.displayOrder} onChange={onChange} />
            <DialogCheckbox disabled={dialogData.gradedReasonId === null} controlId="formGradedReasonIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
        </CommonConfigDialog>
    );
}

export default GradedReasonEditDialog;