import React from 'react';
import { Row, Col, Form, Tooltip, OverlayTrigger } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle } from '@fortawesome/free-solid-svg-icons';

const colSizeLabel = 4;
const colSizeControl = 8;

export function RequiredDialogInput({ controlId, title, value, name, onChange, error, placeholder }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={colSizeControl}>
                <Form.Control required type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredSummaryInput({ title, value, name, onChange, error, placeholder }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Control required type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function SummaryInput({ title, value, name, onChange, error, placeholder }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Control type="text" placeholder={placeholder ?? title} value={value ?? ""} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function DialogIntegerInput({ controlId, title, value, name, onChange, error, placeholder }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={colSizeControl}>
                <Form.Control type="number" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function DialogInput({ controlId, title, value, name, onChange, error, placeholder, readonly, helpText }) {

    if (value === undefined || value === null)
        value = "";

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Col sm={colSizeLabel}>
                <div className="d-flex justify-content-between">
                    <Form.Label>{title}</Form.Label>
                    <HelpText text={helpText} />
                </div>
            </Col>
            <Col sm={colSizeControl}>
                <Form.Control type="text" placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} readOnly={readonly} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredDialogIntegerInput({ controlId, title, value, name, onChange, error, placeholder }) {

    if (value === undefined || value === null)
        value = "";

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId} key={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={colSizeControl}>
                <Form.Control type="number" required placeholder={placeholder ?? title} value={value} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredDialogSelect({ children, controlId, title, value, name, onChange, error }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={colSizeControl}>
                <Form.Select required type="text" className="config-select" value={value ?? ""} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function RequiredSummarySelect({ children, title, value, name, onChange, error }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Select required type="text" className="config-select" value={value ?? ""} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function SummarySelect({ children, title, value, name, onChange, error }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Select type="text" className="config-select" value={value ?? ""} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

export function DialogSelect({ children, controlId, title, value, name, onChange, error }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={colSizeControl}>
                <Form.Select type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function DialogSelectWithMoreConfig({ children, controlId, title, value, name, additionalConfig, onChange, error }) {

    if (value === null)
        value = "";

    var colSizeAdditionalConfig = (additionalConfig == null) ? 0 : 2;
    var colSizeControlWithConfig = colSizeControl - colSizeAdditionalConfig;

    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={colSizeControlWithConfig}>
                <Form.Select type="text" className="config-select" value={value} name={name} onChange={onChange} isInvalid={!!error}>
                    {children}
                </Form.Select>
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
            {additionalConfig &&
                <Col sm={colSizeAdditionalConfig}>
                    {additionalConfig}
                </Col>
            }
        </Form.Group>
    );
}

export function DialogCheckbox({ controlId, title, disabled, checked, name, onChange, error }) {
    return (
        <Form.Group as={Row} className="mb-1" controlId={controlId}>
            <Form.Label column sm={colSizeLabel}>{title}</Form.Label>
            <Col sm={5}>
                <Form.Check type="checkbox" size="lg" disabled={disabled} checked={checked} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </Form.Group>
    );
}

export function SummaryCheckbox({ title, disabled, checked, name, onChange, error }) {
    return (
        <>
            <Form.Label column sm={2}>{title}</Form.Label>
            <Col sm={4}>
                <Form.Check type="checkbox" size="lg" disabled={disabled} checked={checked} name={name} onChange={onChange} isInvalid={!!error} />
                <Form.Control.Feedback type="invalid">
                    {error}
                </Form.Control.Feedback>
            </Col>
        </>
    );
}

function HelpText({ text }) {
    return text && text !== ""
        ?
        <div className="me-2">
            <OverlayTrigger overlay={<Tooltip>{text}</Tooltip>}>
                <FontAwesomeIcon className="link" icon={faQuestionCircle} />
            </OverlayTrigger>
        </div>
        :
        <></>
}