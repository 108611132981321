import React, { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTriangleExclamation } from '@fortawesome/free-solid-svg-icons';

import { useTranslationUtils } from '../Utilities/TranslationUtils';
import { DraggableModalDialog } from './DraggableModalDialog';
import GradedCommentDialog from "./GradedCommentDialog";

function GradedWarningDialog({ showDialog, closeDialog, onSave, warnings, gradedReasons }) {
    const { tp, ts } = useTranslationUtils();
    const [showCommentDialog, setShowCommentDialog] = useState(false);

    const handleSaveWithComment = (comment, reasonCode) => {
        onSave(comment, reasonCode);
        closeDialog();
    }

    return (
        <>
            <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static" size="lg">
                <Modal.Header closeButton>
                    <FontAwesomeIcon icon={faTriangleExclamation} size="xl" className="me-3" />
                    <Modal.Title className="text-danger">{tp("Dialogs.GradingWarning.Title")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p><strong>{tp("Dialogs.GradingWarning.AreYouSure")}</strong></p>
                    <ul>
                        {warnings.map((warning, index) =>
                            <li key={index}><p>{ts(warning.message)}</p></li>)
                        }
                    </ul>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={closeDialog}>
                        {tp('Application.Controls.Cancel')} 
                    </Button>
                    <Button variant="primary" onClick={() => setShowCommentDialog(true)}>
                        {tp('Application.Controls.Ok')}
                    </Button>
                </Modal.Footer>
            </Modal>

            <GradedCommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleSaveWithComment} gradedReasons={gradedReasons} />
        </>
    );
}

export default GradedWarningDialog;