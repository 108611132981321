import React from 'react';
import { useTranslation } from 'react-i18next';
import { Column, UseFiltersColumnOptions } from 'react-table';

import { Quality, QualityValue } from '../../../../Types/Quality';
import NoFilter from '../../../../DataTable/datatable.nofilter';

function PPKColumn(): Column<Quality> & UseFiltersColumnOptions<Quality> {
    const { t } = useTranslation();
    return {
        Header: t('LotDetails.Qualities.PPK').toString(),
        accessor: 'qualityValues',
        Cell: (props) => {
            let ppk = props.row.original.qualityValues.find((qv: QualityValue) => qv.testMethod === "PPK")
            return <>{!!ppk?.resultValue ? Number(ppk.resultValue).toFixed(2) : ""}</>;
        },
        Filter: NoFilter
    }
}

export default PPKColumn;