import { useState, useEffect } from 'react';

import useAlert from '../../Alerts/useAlert'
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import { RequiredDialogInput, RequiredDialogSelect } from '../../DialogInputs';
import CommonConfigDialog from './CommonConfigDialog';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useRecipes, usePackageTypes } from '../../Utilities/Hooks'

export function RecipeToPackageTypeEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [fieldErrors, setFieldErrors] = useState({});
    const { setAlert } = useAlert();
    const [refreshToken, setRefreshToken] = useState(0);
    const { recipes } = useRecipes(refreshToken);
    const { packageTypes } = usePackageTypes(refreshToken);

    useEffect(() => {
        if (showDialog) {
            setRefreshToken(r => r + 1);
        }
    }, [showDialog])

    const savePackageType = (recipeToPackageType) => {
        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/recipeToPackageType`;
            if (recipeToPackageType.id != null) {
                url += `/${recipeToPackageType.id}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(recipeToPackageType))
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        response.text().then(error => {
                            console.log(error)
                            setAlert("danger", "Failed to delete", !!error ? error : "An unexpected error occured")
                        })
                    } else {
                        setAlert("success", "Updated", "Recipe To Package Type has successfully been updated");
                    }
                    resolve(response);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
        });
        return promise;
    }

    const deletePackageType = (recipeToPackageType) => {
        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/recipeToPackageType/${recipeToPackageType.id}`;

            fetchDataAuthenticated(url, "DELETE")
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        response.text().then(error => {
                            console.log(error)
                            setAlert("danger", "Failed to delete", !!error ? error : "An unexpected error occured")
                        })
                    } else {
                        setAlert("success", "Deleted", "Recipe To Package Type has successfully been deleted");
                    }
                    resolve(response);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
        });
        return promise;
    }

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Recipe To Package Type - " + dialogData.materialCode} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={savePackageType} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} wideDialog canDelete deleteFunction={deletePackageType}>
            <RequiredDialogInput controlId="formRecipeToPackageTypeMaterialCode" title="Material Code" name="materialCode" value={dialogData.materialCode} onChange={onChange} />
            <RequiredDialogSelect controlId="formRecipeToPackageTypePackageTypeId" title="Package Type" name="packageTypeId" value={dialogData.packageTypeId} error={fieldErrors.packageTypeId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {packageTypes.map((packageType) => <option value={packageType.id} key={packageType.id}>{packageType.name}</option>)}
            </RequiredDialogSelect>
            <RequiredDialogSelect controlId="formRecipeToPackageTypeRecipeId" title="Recipe" name="recipeId" value={dialogData.recipeId} error={fieldErrors.recipeId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {recipes.map((recipe) => <option value={recipe.recipeId} key={recipe.recipeId}>{recipe.name}</option>)}
            </RequiredDialogSelect>
        </CommonConfigDialog>
    );
}