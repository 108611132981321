import { useEffect, useState } from 'react';
import { Table, Spinner } from 'react-bootstrap';

import { useHttpUtils } from '../../Utilities/useHttpUtils';
import useAlert from '../../Alerts/useAlert';

const GetLotsWithMostRevisions = ({ setRefreshFunc, setVariant }) => {
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();
    const [lots, setLots] = useState([]);
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState();

    useEffect(() => {
        setRefreshFunc(getRevisions);
        getRevisions();
    }, []);

    const getRevisions = async () => {
        setLoading(true);
        await fetchDataAuthenticated("api/v1/admin/lotsWithRevisions/10", "GET")
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => Promise.reject(error));
                }
                return response.json();
            })
            .then(data => {
                setError("");
                setLots(data);
                if (data.length === 0) {
                    setVariant("warning");
                }
                else {
                    setVariant("");
                }
            })
            .catch(error => {
                setLots();
                setVariant("danger");
                setAlert("danger", error.title, error.detail);
                setError(error.detail);
            })
            .finally(() => setLoading(false));
        ;
    }

    const buildTemplate = () => {
        if (loading) {
            return (<Spinner />);
        }
        if (error) {
            return (<div>{error}</div>);
        }

        if (lots.length === 0) {
            return (<div>No Lots</div>);
        }

        return (
            <Table>
                <thead>
                    <tr>
                        <th>Lot Number</th>
                        <th>Lot Revisions</th>
                    </tr>
                </thead>
                <tbody>
                    {lots && lots.map((lot, index) =>
                        <tr key={`${lot.lotNumber}`}>
                            <td>{lot.lotNumber}</td>
                            <td>{lot.numberOfRevisions}</td>
                        </tr>
                    )}
                </tbody>
            </Table>
        )
    }

    return buildTemplate();
}

export default GetLotsWithMostRevisions;