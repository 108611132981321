import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import ExpandingTable from '../../../DataTable/datatable.expandingtable';
import DateColumn from '../../../DataTable/datatable.datecolumn';
import CheckBoxFilter from '../../../DataTable/datatable.checkboxfilter';
import NoFilter from '../../../DataTable/datatable.nofilter';

function GenealogySection({ genealogy, lotNumber }) {

    const [includeIndirect, setIncludeIndirect] = useState(false);
    const [filteredGenealogy, setFilteredGenealogy] = useState([]);

    const { t } = useTranslation();

    useEffect(() => {
        if (genealogy) {
            if (includeIndirect) {
                setFilteredGenealogy(genealogy);
            } else {
                setFilteredGenealogy(genealogy.filter(g => g.sourceLot === lotNumber || g.destinationLot === lotNumber));
            }
        }
    }, [includeIndirect]);
    
    const tableColumns = [
        {
            // Build our expander column
            id: 'expander', // Make sure it has an ID
            Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
                <span {...getToggleAllRowsExpandedProps()}>
                    {isAllRowsExpanded ? '-' : '+'}
                </span>
            ),
            Cell: ({ row }) =>
                // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
                // to build the toggle for expanding a row
                row.canExpand ? (
                    <span
                        {...row.getToggleRowExpandedProps({
                            style: {
                                // We can even use the row.depth property
                                // and paddingLeft to indicate the depth
                                // of the row
                                paddingLeft: `${row.depth * 2}rem`,
                            },
                        })}
                    >
                        {row.isExpanded ? '-' : '+'}
                    </span>
                ) : null,
        },
        {
            Header: t('LotDetails.Genealogy.Transfer'),
            accessor: 'transferID',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.Genealogy.Grade'),
            accessor: 'grade',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.Genealogy.SourceLot'),
            accessor: 'sourceLot',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.Genealogy.DestinationLot'),
            accessor: 'destinationLot',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.Genealogy.Weight'),
            accessor: 'weight',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Genealogy.SourceEquipment'),
            accessor: 'sourceEquipment',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.Genealogy.DestinationEquipment'),
            accessor: 'destinationEquipment',
            filter: 'includesString'
        },
        DateColumn({ header: t('LotDetails.Genealogy.StartTime'), accessor: 'startTime' }),
        DateColumn({ header: t('LotDetails.Genealogy.EndTime'), accessor: 'endTime' })
    ];

    const initialState = {
        sortBy: [
            {
                id: 'startTime',
                desc: false
            },
            {
                id: 'endTime',
                desc: false
            }
        ]
    };

    return genealogy && genealogy.length > 0 ?
        <ExpandingTable columns={tableColumns} data={filteredGenealogy} initialState={initialState} >
            <CheckBoxFilter
                checked={includeIndirect}
                setChecked={setIncludeIndirect}
                name={includeIndirect ? t('LotDetails.Genealogy.HideIndirectTransers') : t('LotDetails.Genealogy.IncludeIndirectTransers')} />
        </ExpandingTable>
        : t('CommonResponses.NoData');
}

export default GenealogySection;