import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { Button, Modal, Row, Col, Card } from 'react-bootstrap';
import exxonLogo from '../../../images/exxon_logo.png';
import jemmacLogo from '../../../images/jemmac_logo.png';
import { useSiteCode } from '../../Utilities/useSiteCode';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import { client as publicClientApplication } from '../../Utilities/AuthUtils';


function AboutDlg(props) {

    AboutDlg.propTypes = {
        isOpen: PropTypes.bool.isRequired,
        toggleModal: PropTypes.func.isRequired,
        copyright: PropTypes.string
    }

    const { t } = useTranslation();
    const siteCode = useSiteCode();
    const [privileges, setPrivileges] = useState();
    const [loading, setLoading] = useState();
    const [version, setVersion] = useState();
    const { fetchDataAuthenticated } = useHttpUtils();

    const account = publicClientApplication.getAllAccounts()[0];

    useEffect(() => {
        setLoading(true);
        fetchDataAuthenticated('api/v1/user/privileges')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setPrivileges(data);
            })
            .catch(error => {
                console.log("Failed to retrieve privileges");
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        fetchDataAuthenticated('api/info/version')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error();
            })
            .then(data => {
                setVersion(data);
            })
            .catch(error => {
                console.log("Failed to retrieve version");
            });
    }, []);

    return (
        <Modal dialogAs={DraggableModalDialog} show={props.isOpen} onHide={props.toggleModal} backdrop="static" size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {t("Dialogs.About.Title")} {t("Application.Title")} ({siteCode})
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{t("Dialogs.About.Heading")} :</p>
                <Card>
                    <Card.Body>
                        <p>{t("Dialogs.About.Body1")}</p>
                        <p>{t("Dialogs.About.Body2")}</p>
                        <p>{t("Dialogs.About.Body3")}</p>
                    </Card.Body>
                </Card>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Application.Abbreviation")} {t("Dialogs.About.Version")}:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{version}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Dialogs.About.User")}:</p>
                            </Col>
                            <Col xs={9}>
                                <p>{account.username}</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <p>{t("Dialogs.About.Privileges")}:</p>
                            </Col>
                            <Col xs={9}>
                                {loading
                                    ? <p className="fst-italic">Getting privileges...</p>
                                    : <p>{privileges && privileges.join(' ')}</p>
                                }
                            </Col>
                        </Row>
                    </Card.Body>
                </Card>
                <p className="text-center">{t("Dialogs.About.DevelopedBy")}</p>
                <Row>
                    <Col className="d-flex justify-content-md-center">
                        <img src={exxonLogo} alt="ExxonMobil logo" />
                    </Col>
                    <Col className="d-flex justify-content-md-center">
                        <img src={jemmacLogo} alt="Jemmac logo" />
                    </Col>
                </Row>
                <p className="text-center">{props.copyright}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button color="primary" onClick={props.toggleModal}>{t("Application.Controls.Ok")}</Button>
            </Modal.Footer>
        </Modal>
    );
}

export default AboutDlg;