import React, { Dispatch, SetStateAction, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Row } from 'react-table';

import { Quality, QualityValue } from '../../../../Types/Quality';
import GetQualityColumnClass from './GetQualityColumnClass';

import './QualitiesSection.css';

export interface EditableQualityCellProps {
    row: Row<Quality>,
    qualityType: string,
    qualities: Quality[],
    setQualities: Dispatch<SetStateAction<Quality[]>>,
    canEdit: boolean,
};

function EditableQualityCell({ row, qualityType, qualities, setQualities, canEdit }: EditableQualityCellProps): React.JSX.Element {
    const intialValue = row.original.qualityValues.find((qv) => qv.testMethod === qualityType)?.resultValue;
    const [value, setValue] = useState(intialValue ?? "");
    let className = "";

    const updateQualityCell = (rowIndex: number, value: string) => {
        const MANUAL_TEST_METHOD = "ALTERNATE";
        let quality = qualities[rowIndex];
        let qualityValue = quality.qualityValues.find((qv) => qv.testMethod === MANUAL_TEST_METHOD);

        // If we don't have an existing quality value, and we don't already have a manual test quality value then we can do nothing
        // This case will trigger when an input is clicked and nothing is inputted
        if (!qualityValue && !value) {
            return;
        }

        if (!qualityValue) {
            console.log("No manual quality value found. Adding the manual entry");

            // All quality values will have the same result type so just get the first
            let resultType = quality.qualityValues[0]?.resultType ?? "";
            let manualQualityValue: QualityValue = {
                resultValue: value,
                resultType: resultType,
                status: "Hi Unused",
                testMethod: MANUAL_TEST_METHOD,
                qualityValueSamples: [],
                isDefault: false,
                graderSelected: true
            };
            quality.qualityValues.push(manualQualityValue);
            updateQualityValueStatus(quality, MANUAL_TEST_METHOD);
        } else {
            // Remove manual test value
            if (!value) {
                quality.qualityValues = quality.qualityValues.filter(qv => qv.testMethod !== MANUAL_TEST_METHOD);
                // If the manual value is used we need to set a new quality value to used status
                let defaultUsedValue = quality.qualityValues.find(qv => !qv.status.includes("Unused"))?.testMethod
                    ?? quality.qualityValues.find(qv => qv.resultValue)?.testMethod
                    ?? "LBMLABCALC";
                updateQualityValueStatus(quality, defaultUsedValue);
            } else {
                // Update manual test value
                qualityValue.resultValue = value;
                updateQualityValueStatus(quality, MANUAL_TEST_METHOD);
            }
        }

        setQualities([...qualities.map((q) => q.name === quality.name ? quality : q)]);
    };

    const updateQualityValueStatus = (quality: Quality, usedMethod: string): void => {
        // Update quality value statuses so that the only used value is value for usedMethod
        quality.qualityValues = quality.qualityValues.map((qv) => {
            if (qv.testMethod === usedMethod) {
                var newStatus = qv.status.replace(" Unused", "");
                return { ...qv, status: newStatus, graderSelected: true };
            }

            if (qv.testMethod !== usedMethod && !qv.status.includes("Unused")) {
                return { ...qv, status: qv.status + " Unused", graderSelected: false };
            }

            return qv;
        });
    }


    row.original.qualityValues.forEach(function (qualityValue:QualityValue) {
        if (qualityValue.testMethod === qualityType) {
            className = GetQualityColumnClass(qualityValue);
        }
    });

    return <Form.Control
        type="text"
        className={'quality-value-column ' + className}
        name={qualityType}
        value={value}
        onChange={(e) => setValue(e.target.value)}
        onBlur={(e) => updateQualityCell(row.index, e.currentTarget.value)}
        plaintext={!canEdit}
        readOnly={!canEdit}
    />;
}

export default EditableQualityCell;