import React from 'react';
import { Row, Col, Badge } from 'react-bootstrap';
import { HashLink } from 'react-router-hash-link';

export function SectionNavigation({ sections }) {

    return (
        <Row className="gy-2 mb-2">
            {sections.map(section =>
                <Col sm xs={3} key={`${section.key}-section-link`}>
                    <HashLink to={`#${section.key}-section`}>
                        <Badge bg="secondary" className="hashlink-text w-100 lighter">
                            {section.title}
                        </Badge>
                    </HashLink>
                </Col>
            )}
        </Row>
    );
}
