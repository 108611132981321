import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { DraggableModalDialog } from './DraggableModalDialog';
import {useTranslation} from "react-i18next";
import {RequiredDialogSelect} from "../DialogInputs";

function GradedCommentDialog({ showDialog, closeDialog, onSave, gradedReasons }) {

    const { t, i18n } = useTranslation();

    const [comment, setComment] = useState("");
    const [reasonCode, setReasonCode] = useState("");
    const [fieldErrors, setFieldErrors] = useState({});

    const onChange = (e) => {
        setComment(e.currentTarget.value);
    }

    const onReasonCodeChange = (e) => {
        let field = e.currentTarget.name;
        let value = e.currentTarget.value;
        
        setReasonCode(value);
        
        if (e.currentTarget.validity.valid) {
            setFieldErrors({
                ...fieldErrors,
                [field]: null
            });
        }
        else {
            setFieldErrors({
                ...fieldErrors,
                [field]: e.currentTarget.validationMessage
            });
        }
        
    }

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSave(comment, reasonCode);
        closeDialog();
    }

    return (
        <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('LotDetails.GradingComments.GradedCommentTitle')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSave} id="commentForm">
                    <RequiredDialogSelect controlId="commentForm" title={t('LotDetails.GradingComments.GradedReasonCode')} name="reasonCode" value={reasonCode} error={fieldErrors.reasonCode} onChange={onReasonCodeChange}>
                        <option value="" key={0} >{t("Application.Controls.DefaultSelect")}...</option>
                        {gradedReasons.map((gr) =>
                            <option value={gr.reasonCode} key={gr.gradedReasonId}>{i18n.language !== 'en' && gr.translation ? t(gr.translation) : gr.description}</option>
                        )}
                    </RequiredDialogSelect>
                    <Form.Control as="textarea" rows="6" value={comment} onChange={onChange} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    {t('Application.Controls.Close')}
                </Button>
                <Button variant="primary" type="submit" form="commentForm">
                    {t('Application.Controls.Save')}
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default GradedCommentDialog;