import LotSplitDialog from './LotSplitDialog';
import LotSplitDialog_HCC from './LotSplitDialog_HCC';

const LotSplitDialogSelector = ({ businessUnitCode, showDialog, closeDialog, lotNumber, buildStart, buildEnd, getLotDetail, currentGrade, alternativeGrades, allGrades, loadingAlternativeGrades, loadingAllGrades, unreliableBaggingData }) => {
    let lotSplitDialogData = {
        showDialog: showDialog,
        closeDialog: closeDialog,
        lotNumber: lotNumber,
        buildStart: buildStart,
        buildEnd: buildEnd,
        getLotDetail: getLotDetail,
        currentGrade: currentGrade,
        alternativeGrades: alternativeGrades,
        allGrades: allGrades,
        loadingAlternativeGrades: loadingAlternativeGrades,
        loadingAllGrades: loadingAllGrades,
        unreliableBaggingData: unreliableBaggingData
    };

    switch (businessUnitCode) {
        case "HCC_GPPE":
        case "HCC_PP":
        case "HCC_LDPE":
            return <LotSplitDialog_HCC {...lotSplitDialogData} />
        default:
            return <LotSplitDialog {...lotSplitDialogData} />
    }
}

export default LotSplitDialogSelector;