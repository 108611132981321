import React, { Dispatch, SetStateAction } from 'react';
import { Column, UseFiltersColumnOptions } from 'react-table';

import NoFilter from '../../../../DataTable/datatable.nofilter';
import { Quality } from '../../../../Types/Quality';
import EditableQualityCell from './EditableQualityCell';

export interface EditableQualityColumnProps {
    header: string,
    canEdit: boolean,
    qualityType: string,
    qualities: Quality[],
    setQualities: Dispatch<SetStateAction<Quality[]>>,
};

function EditableQualityColumn({ header, canEdit, qualityType, qualities, setQualities }: EditableQualityColumnProps): Column<Quality> & UseFiltersColumnOptions<Quality> {
    return {
        Header: header,
        id: header,
        accessor: "qualityValues",
        Cell: (props) => <EditableQualityCell row={props.row} qualityType={qualityType} qualities={qualities} setQualities={setQualities} canEdit={canEdit} />,
        Filter: NoFilter
    }
}

export default EditableQualityColumn;