export const handleInputChange = (e, dialogData, setDialogData, fieldErrors, setFieldErrors) => {
    let field = e.currentTarget.name;

    let value = e.currentTarget.value;
    if (e.currentTarget.type === "select-one") {
        value = value || null;
    }
    if (e.currentTarget.type === "checkbox") {
        value = e.currentTarget.checked;
    }

    e.currentTarget.setCustomValidity("");

    setDialogData({
        ...dialogData,
        [field]: value
    });

    if (e.currentTarget.validity.valid) {
        setFieldErrors({
            ...fieldErrors,
            [field]: null
        });
    }
    else {
        setFieldErrors({
            ...fieldErrors,
            [field]: e.currentTarget.validationMessage
        });
    }
}