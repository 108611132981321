import React, { createContext, FC, ReactNode, useState, useEffect } from "react";
import { useHttpUtils } from "../Utilities/useHttpUtils";

export type Privileges = {
    admin?: boolean,
    supervisor?: boolean,
    canProcess?: boolean,
    canReview?: boolean,
    canGrade?: boolean,
    canConfigure?: boolean,
    setBusinessUnitCode?: (code:string)=>void
}

const PrivilegeContext = createContext<Privileges>({})

export const PrivilegeProvider: FC<{ children: ReactNode }> = ({ children }) => {

    const { fetchDataAuthenticated } = useHttpUtils();
    const [businessUnitCode, setBusinessUnitCode] = useState<string>();
    const [admin, setAdmin] = useState<boolean>();
    const [supervisor, setSupervisor] = useState<boolean>();
    const [canProcess, setCanProcess] = useState<boolean>();
    const [canReview, setCanReview] = useState<boolean>();
    const [canGrade, setCanGrade] = useState<boolean>();
    const [canConfigure, setCanConfigure] = useState<boolean>();

    useEffect(() => {
        async function getPrivileges() {
            if (admin === undefined) {
                let isAdmin = await getAuthenticatedAccountPrivilege('AdminPrivilegeName', businessUnitCode);
                setAdmin(isAdmin);
            }
            if (canConfigure === undefined) {
                let isCanConfigure = await getAuthenticatedAccountPrivilege('CanConfigurePrivilegeName', businessUnitCode);
                setCanConfigure(isCanConfigure);
            }

            if (businessUnitCode) {
                let isSupervisor = await getAuthenticatedAccountPrivilege('SupervisorPrivilegeName', businessUnitCode);
                setSupervisor(isSupervisor);
                let isCanProcess = await getAuthenticatedAccountPrivilege('CanProcessPrivilegeName', businessUnitCode);
                setCanProcess(isCanProcess);
                let isCanReview = await getAuthenticatedAccountPrivilege('CanReviewPrivilegeName', businessUnitCode);
                setCanReview(isCanReview);
                let isCanGrade = await getAuthenticatedAccountPrivilege('CanGradePrivilegeName', businessUnitCode);
                setCanGrade(isCanGrade);
            }
        }
        getPrivileges();
    }, [businessUnitCode]);

    async function getAuthenticatedAccountPrivilege(privilegeName:string, businessUnitCode:string | undefined) {
        let accessGranted = false;

        let url = `api/v1/user/checkPrivilege?privilegeName=${privilegeName}`;

        if (businessUnitCode) {
            url += `&businessUnitCode=${businessUnitCode}`;
        }

        await fetchDataAuthenticated(url)
            .then(response => {
                if (!response.ok) {
                    throw new Error(`Failed to check privilege ${privilegeName} for business unit ${businessUnitCode}`);
                }
                return response.json();
            })
            .then(data => {
                accessGranted = !!data
            })
            .catch(error => console.error(error.message));

        return accessGranted;
    }

    return (
        <PrivilegeContext.Provider value={{admin, supervisor, canProcess, canReview, canGrade, canConfigure, setBusinessUnitCode} }>
            {children}
        </PrivilegeContext.Provider>
    )
}

export default PrivilegeContext;