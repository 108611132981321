import { useEffect, useState } from 'react';
import { Table, Spinner } from 'react-bootstrap';

import { useHttpUtils } from '../../Utilities/useHttpUtils';
import DateAsLocaleString from '../../Utilities/DateAsLocaleString';
import useAlert from '../../Alerts/useAlert';

const GetHeartbeats = ({ setRefreshFunc, setVariant}) => {
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();
    const [heartbeats, setHeartbeats] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    useEffect(() => {
    },[heartbeats])

    useEffect(() => {
        setRefreshFunc(getHeatbeats);
        getHeatbeats();
    }, []);

    const getHeatbeats = () => {
        setLoading(true);
        fetchDataAuthenticated("api/v1/admin/heartbeat", "GET")
            .then(response => {
                if (!response.ok) {
                    return response.json().then(error => Promise.reject(error));
                }
                return response.json();
            })
            .then(data => {
                setError("");
                setHeartbeats(data);
                if (data.length === 0) {
                    setVariant("warning");
                } else {
                    setVariant("");
                }
            })
            .catch(error => {
                setHeartbeats(null);
                setVariant("danger");
                setAlert("danger", error.title, error.detail);
                setError(error.detail);
            })
            .finally(() => setLoading(false));
    }

    const buildTemplate = () => {
        if (loading) {
            return (<Spinner />);
        }
        if (error) {
            return (<div>{error}</div>);
        }

        if (heartbeats.length === 0) {
            return (<div>No Hearbeats</div>);
        }

        return (
            <Table className="w-100">
                <thead>
                    <tr>
                        <th>Business Unit</th>
                        <th>Message Last Recieved</th>
                    </tr>
                </thead>
                <tbody>
                    {heartbeats && heartbeats.map((heartbeat, index) =>
                        <tr key={`heartbeat-${index}`}>
                            <td>{heartbeat.businessUnit}</td>
                            <td><DateAsLocaleString date={heartbeat.messageSentAtLocalTime} /></td>
                        </tr>
                    )}
                </tbody>
            </Table>
        )
    }

    return buildTemplate();
}

export default GetHeartbeats;