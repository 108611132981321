import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import LineQualityDataEditDialog from '../../Dialogs/Configuration/LineQualityDataEditDialog';
import { Nav, Form } from 'react-bootstrap';
import { useBusinessUnits, useLines, useQualities } from '../../Utilities/Hooks';
import CommonConfigPage from './CommonConfigPage';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function LineQualityDataTable({ onRowClick, includeDeleted, businessUnitId, lineId, qualityId, refresh }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(true);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getLineQualityData = async function () {
            let url = `api/v1/linequalitydata/?includeDeleted=${includeDeleted}`;
            if (businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;
            if (lineId > 0)
                url += `&lineId=${lineId}`;
            if (qualityId > 0)
                url += `&qualityId=${qualityId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getLineQualityData();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Line',
            accessor: 'lineName',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Quality',
            accessor: 'qualityName',
            filter: 'includesString'
        },
        {
            Header: 'Value To Use',
            accessor: 'defaultValueToUse',
            filter: 'includesString'
        },
        {
            Header: 'Transfer Calc',
            accessor: 'transferCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'Transfer Offspec?',
            accessor: 'transferIsOffspecFunction',
            filter: 'includesString'
        },
        {
            Header: 'Batch Calc',
            accessor: 'batchCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'Batch Lab Calc',
            accessor: 'batchLabCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'Batch Offspec?',
            accessor: 'batchIsOffspecFunction',
            filter: 'includesString'
        },
        {
            Header: 'Lot Calc',
            accessor: 'lotCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'Lot Lab Calc',
            accessor: 'lotLabCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'Lot Offspec?',
            accessor: 'lotIsOffspecFunction',
            filter: 'includesString'
        },
        {
            Header: 'PO Lot Calc',
            accessor: 'packoutLotCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'PO Lot Lab Calc',
            accessor: 'packoutLotLabCalculateFunction',
            filter: 'includesString'
        },
        {
            Header: 'PO Lot Offspec?',
            accessor: 'packoutLotIsOffspecFunction',
            filter: 'includesString'
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {} , getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function LineQualityDataConfiguration() {

    const businessUnits = useBusinessUnits(false);
    const [businessUnitId, setBusinessUnitId] = useState(0);

    const lines = useLines(false, businessUnitId);
    const [lineId, setLineId] = useState(0);

    const qualities = useQualities(false, businessUnitId);
    const [qualityId, setQualityId] = useState(0);

    const changeBusinessUnitFilter = (e) => {
        setBusinessUnitId(e.currentTarget.value);
        setLineId(0);
        setQualityId(0);
    }

    return (
        <CommonConfigPage
            title="Line Quality Data"
            table={(includeDeleted, showEditDialog, refreshList) => <LineQualityDataTable onRowClick={showEditDialog} includeDeleted={includeDeleted} businessUnitId={businessUnitId} lineId={lineId} qualityId={qualityId} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <LineQualityDataEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnits={businessUnits}/>}
        >
            <Form.Select type="text" value={businessUnitId} className="filter-select float-start" onChange={changeBusinessUnitFilter}>
                <option value={0} key={0}>All Business Units</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </Form.Select>
            <Form.Select type="text" value={lineId} className="filter-select float-start" onChange={(e) => { setLineId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Lines</option>
                {lines.map((l) => <option value={l.lineId} key={l.lineId}>{l.name}</option>)}
            </Form.Select>
            <Form.Select type="text" value={qualityId} className="filter-select float-start" onChange={(e) => { setQualityId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Qualities</option>
                {qualities.map((q) => <option value={q.qualityId} key={q.qualityId}>{q.name}</option>)}
            </Form.Select>
        </CommonConfigPage>
    );
}

export default LineQualityDataConfiguration;