import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import { Row } from 'react-table';

import GetQualityColumnClass from './GetQualityColumnClass';
import LabDetailsDialog from '../../../../Dialogs/LabDetailsDialog';
import { Quality, QualityValue, QualityValueSample } from '../../../../Types/Quality';

export interface LBMLabCellProps {
    row: Row<Quality>,
    qualityName:string,
    qualityType:string
}

function LBMLabCell({ row, qualityName, qualityType }: LBMLabCellProps): React.JSX.Element {
    const [showLabDetails, setShowLabDetails] = useState(false);

    let samples: QualityValueSample[] = [];
    let value = null;
    let className = "";

    row.original.qualityValues.forEach(function (qualityValue:QualityValue) {
        if (qualityValue.testMethod === qualityType) {
            samples = qualityValue.qualityValueSamples;
            value = qualityValue.resultValue + " (" + samples.length + ")";
            className = GetQualityColumnClass(qualityValue) + " lab";
        }
    });

    return (
        <>
            <Button variant="link" className={'quality-value-column link ' + className} onClick={() => setShowLabDetails(true)}>{value}</Button>
            <LabDetailsDialog showDialog={showLabDetails} closeDialog={() => setShowLabDetails(false)} qualityName={qualityName} samples={samples} />
        </>
    );
}

export default LBMLabCell;