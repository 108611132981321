import { useContext, useEffect } from 'react';
import PrivilegeContext from '../Contexts/PrivilegeContext'

export function usePrivileges(businessUnitCode) {
    const { admin, supervisor, canReview, canProcess, canGrade, setBusinessUnitCode } = useContext(PrivilegeContext)

    useEffect(() => {
        if (setBusinessUnitCode) {
            setBusinessUnitCode(businessUnitCode);
        }
    }, [businessUnitCode]);

    return { canProcess, canReview, canGrade, supervisor, admin };
}

export default usePrivileges;