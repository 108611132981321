import { useContext, useEffect, useState} from 'react';

import ConfigurationContext from '../Contexts/ConfigurationContext';

const DateAsLocaleString = ({date, options}) => {
    const configurationContext = useContext(ConfigurationContext);
    const [dateString, setDateString] = useState("");

    useEffect(() => {
        if (date == null) {
            return null;
        }

        if (configurationContext.configurationData.length > 0) {
            let locale = configurationContext.configurationData.find(element => element.name === "Locale");

            if (locale) {
                setDateString(new Date(date).toLocaleString(locale.value, options));
            } else {
                console.warn("Locale not found. Add a configuration entry for 'Locale' in Configuration Data with a valid locale, e.g. en-GB");
                setDateString(new Date(date).toISOString(locale.value, options));
            }
        }
    }, [date, configurationContext.configurationData, options]);

    return (
        <>
            {dateString}
        </>
    );
}

export default DateAsLocaleString;