import { useState, useEffect } from 'react';
import { useHttpUtils } from './useHttpUtils';

export function useSiteCode() {
    const [siteCode, setSiteCode] = useState();
    const { fetchDataAuthenticated } = useHttpUtils();

    useEffect(() => {
        const getSite = async function () {
            await fetchDataAuthenticated("api/config/siteCode")
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setSiteCode(data);
                })
                .catch(error => {
                    console.log("Failed to get site code");
                });
        }

        getSite();
    }, []);

    return siteCode;
}