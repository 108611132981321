import { useState } from 'react';
import { Container, Tab, Nav, NavDropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { usePrivileges } from '../Auth/usePrivileges';
import BusinessUnits from './Configuration/BusinessUnits';
import ConfigurationData from './Configuration/ConfigurationData';
import EquipmentTypes from './Configuration/EquipmentTypes';
import Equipment from './Configuration/Equipment';
import Lines from './Configuration/Lines';
import Qualities from './Configuration/Qualities';
import LineQualityData from './Configuration/LineQualityData';
import LabResultsConfig from './Configuration/LabResultsConfig';
import RecipeTypes from './Configuration/RecipeTypes';
import RecipeProperties from './Configuration/RecipeProperties';
import RecipeDataTypes from './Configuration/RecipeDataTypes';
import RecipeData from './Configuration/RecipeData';
import Recipes from './Configuration/Recipes';
import GradedReasons from './Configuration/GradedReasons';
import Emails from './Configuration/Emails';
import ChangeLog from './ChangeLog';
import PackageType from './Configuration/PackageType';
import RecipeToPackageType from './Configuration/RecipeToPackageType';

function Configuration({initialSelectedTab}) {
    const { t } = useTranslation();
    const { admin } = usePrivileges();
    const navigate = useNavigate();

    let configTabData = [
        { eventKey: "businessUnits", title: 'Configuration.Tabs.BusinessUnits', component: <BusinessUnits/> },
        { eventKey: "configData", title: 'Configuration.Tabs.ConfigurationData', component: <ConfigurationData/> },
        { eventKey: "equipmentTypes",title: 'Configuration.Tabs.EquipmentTypes', component: <EquipmentTypes/> },
        { eventKey: "equipment",title: 'Configuration.Tabs.Equipment', component: <Equipment/> },
        { eventKey: "qualities", title: 'Configuration.Tabs.Qualities', component: <Qualities/>},
        { eventKey: "lines",title: 'Configuration.Tabs.Lines', component: <Lines/>},
        { eventKey: "lineQualityData", title: 'Configuration.Tabs.LineQualityData', component: <LineQualityData/> },
        { eventKey: "labResultsConfig", title: 'Configuration.Tabs.LabResultsConfig', component: <LabResultsConfig/> },
        { eventKey: "recipeTypes", title: 'Configuration.Tabs.RecipeTypes', component: <RecipeTypes/> },
        { eventKey: "recipeProperties", title: 'Configuration.Tabs.RecipeProperties', component: <RecipeProperties/> },
        { eventKey: "recipeDataTypes", title: 'Configuration.Tabs.RecipeDataTypes', component: <RecipeDataTypes /> },
        { eventKey: "recipeData", title: 'Configuration.Tabs.RecipeData', component: <RecipeData /> },
        { eventKey: "recipes", title: 'Configuration.Tabs.Recipes', component: <Recipes /> },
        { eventKey: "gradedReasons", title: 'Configuration.Tabs.GradedReasons', component: <GradedReasons/> },
        { eventKey: "emails", title: 'Configuration.Tabs.Emails', component: <Emails /> },
        { eventKey: "packageTypes", title: 'Configuration.Tabs.PackageTypes', component: <PackageType /> },
        { eventKey: "recipeToPackageTypes", title: 'Configuration.Tabs.RecipeToPackageTypes', component: <RecipeToPackageType /> },
        { eventKey: "changeLog", title: 'Configuration.Tabs.ChangeLog', component: <ChangeLog/>, adminOnly:true },
    ]

    const initialTabData = configTabData.find(t => t.eventKey === initialSelectedTab)
    const [selectedTab, setSelectedTab] = useState(initialTabData?.title ?? configTabData[0].title);
    const changeSelectedTab = (newTabEventKey) => {
        let tabData = configTabData.find(t => t.eventKey === newTabEventKey);
        setSelectedTab(tabData.title);
        navigate(`/config/${tabData.eventKey}`, { shallow: true });
    }

    return (
        <Container fluid>
            <h3 className="ms-2">{t('Configuration.Title')}</h3>
            <Tab.Container defaultActiveKey={initialTabData?.eventKey ?? configTabData[0].eventKey} id="settings-tabs" mountOnEnter={true} justify>
                <Nav variant="tabs" className="tabs mt-3 mb-3">
                    <NavDropdown title={t(selectedTab)} className="fs-5">
                        {configTabData.map(tabData =>
                            (admin || !tabData.adminOnly) &&
                            <NavDropdown.Item key={`configTab-${tabData.eventKey}`} eventKey={tabData.eventKey} onClick={() => changeSelectedTab(tabData.eventKey)}>
                                {t(tabData.title)}
                            </NavDropdown.Item>
                        )}
                    </NavDropdown>
                </Nav>
                <Tab.Content>
                    {configTabData.map(tabData =>
                        (admin || !tabData.adminOnly) &&
                        <Tab.Pane key= {`configTabPane-${tabData.eventKey}`} eventKey={tabData.eventKey}>{tabData.component}</Tab.Pane>
                    )}
                </Tab.Content>
            </Tab.Container>
        </Container >
    );
}

export default Configuration;