import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, RequiredDialogInput, RequiredDialogSelect, DialogSelect, DialogCheckbox } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useEquipmentTypes, usePackageTypes } from '../../Utilities/Hooks';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function EquipmentEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [fieldErrors, setFieldErrors] = useState({});
    const equipmentTypes = useEquipmentTypes(false, dialogData.businessUnitId);
    const { packageTypes } = usePackageTypes();

    console.log(packageTypes)

    const saveEquipment = (equipment) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/equipment`;
            if (equipment.equipmentId != null) {
                url += `/${equipment.equipmentId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(equipment))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
            });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    return (
        <CommonConfigDialog title={"Equipment - " + dialogData.name} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveEquipment} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors}>
            <RequiredDialogInput controlId="formEquipmentName" title="Name" name="name" value={dialogData.name} error={fieldErrors.name} onChange={onChange} />
            <DialogInput controlId="formEquipmentSamplePoint" title="Sample Point" name="samplePoint" value={dialogData.samplePoint} error={fieldErrors.samplePoint} onChange={onChange} />
            <RequiredDialogSelect controlId="formEquipmentBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <RequiredDialogSelect controlId="formEquipmentEquipmentType" title="Equipment Type" name="equipmentTypeId" value={dialogData.equipmentTypeId} error={fieldErrors.equipmentTypeId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {equipmentTypes.map((et) => <option value={et.equipmentTypeId} key={et.equipmentTypeId}>{et.name}</option>)}
            </RequiredDialogSelect>
            <DialogSelect controlId="formEquipmentDefaultPackageType" title="Default Package Type" name="defaultPackageTypeId" value={dialogData.defaultPackageTypeId} error={fieldErrors.defaultPackageTypeId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {packageTypes.map((pt) => <option value={pt.id} key={pt.id}>{pt.name}</option>)}
            </DialogSelect>
            <DialogCheckbox disabled={dialogData.equipmentId === null} controlId="formEquipmentIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} /> 
        </CommonConfigDialog> 
    );
}

export default EquipmentEditDialog;