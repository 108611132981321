import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'react-bootstrap';
import { usePrivileges } from '../../../Auth/usePrivileges';
import { useHttpUtils } from '../../../Utilities/useHttpUtils';
import useAlert from '../../../Alerts/useAlert';

import { useTranslationUtils } from '../../../Utilities/TranslationUtils';

function WarningsCategorySection({ warnings, category, sectionTitle }) {
    const { tp, ts } = useTranslationUtils();

    if (!warnings)
        return (<></>);

    var categoryWarnings = warnings;
    if (category === "*") {
        categoryWarnings = warnings.filter((warning) => warning.displayed !== true);
    } else {
        categoryWarnings = warnings.filter((warning) => warning.category === category);
    }

    if (categoryWarnings.length === 0)
        return (<></>);

    categoryWarnings.forEach((item) => { item.displayed = true })

    var warningList = categoryWarnings.map((warning, i) => (
        <li key={`w${i}`}>{ts(warning.message)}</li>
    ));

    return (
        <>
        <b>{sectionTitle}</b>
        <ul className="warnings">
            {warningList}
            </ul>
        </>
    )
}

function WarningsBlockedSection({ warnings, category, action, disabled }) {
    const { t } = useTranslation();

    if (!warnings)
        return(<></>);

    var categoryWarnings = warnings.filter((warning) => warning.category === category);

    categoryWarnings.forEach((item) => { item.displayed = true })

    if (categoryWarnings.length === 0)
        return (<></>);

    var warningList = categoryWarnings.map((warning) => (
            <p>{warning.message} {warning.category === "BLOCKED" &&
                <Button variant="primary me-1"
                disabled={disabled}
                        onClick={() => action()}>
                    {t('LotDetails.Warnings.Unblock')}
                </Button>}</p>
    ));

    return warningList;
}

function WarningsSection({ warnings, lotNumber, getLotDetail, businessUnitCode }) {

    const { t } = useTranslation();
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();
    const { canProcess, admin } = usePrivileges(businessUnitCode);

    if (!warnings)
        return (<></>);

    const unblockLot = () => {
        fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/unblock`, 'PUT')
            .then(async response => {
                if (response.ok) {
                    getLotDetail();
                } else {
                    throw new Error("Failed to unblock the lot");
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            });
    }

    return (
        <>
            <WarningsBlockedSection warnings={warnings} category="BLOCKED" action={unblockLot} disabled={!canProcess && !admin} />
            <WarningsCategorySection warnings={warnings} category="PROCESS" sectionTitle={t('LotDetails.Warnings.WarningsFromProcessing')} />
            <WarningsCategorySection warnings={warnings} category="REVIEW" sectionTitle={t('LotDetails.Warnings.WarningsFromLotReview')} />
            <WarningsCategorySection warnings={warnings} category="GRADE" sectionTitle={t('LotDetails.Warnings.WarningsFromGrading')} />
            <WarningsCategorySection warnings={warnings} category="*" sectionTitle={t('LotDetails.Warnings.OtherWarnings')} />
        </>
    )
}

export default WarningsSection;