import React from 'react';
import { Column, UseFiltersColumnOptions } from 'react-table';

import { Quality } from '../../../../Types/Quality';
import NoFilter from '../../../../DataTable/datatable.nofilter';
import QualityCell from './QualityCell';

export interface QualityColumnProps {
    header: string,
    qualityType: string,
}

function QualityColumn({ header, qualityType }: QualityColumnProps): Column<Quality> & UseFiltersColumnOptions<Quality>  {
    return {
        Header: header,
        id: header,
        accessor: "qualityValues",
        Cell: (props) => <QualityCell row={props.row} qualityType={qualityType} />,
        Filter: NoFilter
    }
}

export default QualityColumn;