import React from 'react';
import { Container, Tabs, Tab } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import Lots from './Reporting/Lots';
import Qirs from './Reporting/QualityIncidentReports';

function Reporting({ status }) {
    const { t } = useTranslation();

    return (
        <Container fluid>
            <Tabs defaultActiveKey="lots" id="reporting-tabs" className="tabs mt-3 mb-3" mountOnEnter={true} fill>
                <Tab eventKey="lots" title={t("Reporting.LotsTabTitle")}>
                    <Lots status={status} />
                </Tab>
                <Tab eventKey="qirs" title={t("Reporting.QIRsTabTitle")}>
                    <Qirs status={status} />
                </Tab>
            </Tabs>
        </Container>
    );
}

export default Reporting;