import React from 'react';
import { useBusinessUnits, useRecipe } from "../Utilities/Hooks";
import RecipeEditDialog from "./Configuration/RecipeEditDialog";

export type RecipeDialogProps = {
    name: string,
    businessUnitCode: string,
    showDialog: boolean,
    closeDialog: Function
};

function RecipeDialog({ name, businessUnitCode, showDialog, closeDialog }: RecipeDialogProps) {

    const { recipe, setRecipe } = useRecipe(name, businessUnitCode);
    const businessUnits = useBusinessUnits();

    return recipe ? <RecipeEditDialog dialogData={recipe} setDialogData={setRecipe} showDialog={showDialog} closeDialog={closeDialog} businessUnits={businessUnits} /> : <></>
}

export default RecipeDialog;