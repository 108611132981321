import React, { useState } from 'react';
import { Spinner } from 'react-bootstrap';
import ServerSideDataTable from '../DataTable/datatable.server';
import NoFilter from '../DataTable/datatable.nofilter';
import { useHttpUtils } from '../Utilities/useHttpUtils';
import useAlert from '../Alerts/useAlert';
import * as OData from '../DataTable/datatable.ODataHelper';

function ChangeLog() {

    const { setAlert } = useAlert()
    const { fetchDataAuthenticated } = useHttpUtils();

    const [loading, setLoading] = useState();
    const [data, setData] = useState([]);
    const [filteredCount, setFilteredCount] = useState();
    const [count, setCount] = useState();

    function getChangeLogs(pageNumber, pageSize, sorting, filters) {
        let params = new URLSearchParams();

        // default sort
        if (sorting.length === 0) {
            sorting = [{ id: "dateChanged", desc: true }]
        }

        OData.addSorting(params, sorting);
        OData.addFilters(params, filters);
        OData.addPaging(params, pageSize, pageNumber);

        fetchDataAuthenticated(`api/v1/changeLog?${params.toString()}`)
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Failed to get change log');
            })
            .then(data => {
                setData(data.data);
                setFilteredCount(data.filteredCount);
                setCount(data.totalCount);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            })
    };
    
    const tableColumns = [
        {
            Header: 'Entity Name',
            accessor: 'entityName',
            filter: 'includesString'
        },
        {
            Header: 'Primary Key Value',
            accessor: 'primaryKeyValue',
            filter: 'includesString'
        },
        {
            Header: 'Property Name',
            accessor: 'propertyName',
            filter: 'includesString'
        },
        {
            Header: 'Old Value',
            accessor: 'oldValue',
            filter: 'includesString'
        },
        {
            Header: 'New Value',
            accessor: 'newValue',
            filter: 'includesString'
        },
        {
            Header: 'Date Changed',
            accessor: 'dateChanged',
            Filter: NoFilter
        },
        {
            Header: 'Account',
            accessor: 'account',
            filter: 'includesString'
        }
    ];

    const initialState = {
        pageSize: 40
    };

    return (
        <>
            {loading &&
                <div className="overlay-content">
                    <Spinner animation="border" />
                </div>
            }

            <ServerSideDataTable
                id="ChangeLog"
                tableColumns={tableColumns}
                tableData={data}
                initialState={initialState}
                queryFunction={getChangeLogs}
                totalRecords={count}
                totalFilteredRecords={filteredCount}
            />
        </>
    );
}

export default ChangeLog;