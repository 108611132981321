import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useTranslation } from 'react-i18next';
import DataTable from '../DataTable/datatable.component';
import { Link } from 'react-router-dom';
import DateColumn from '../DataTable/datatable.datecolumn';
import { getTableFilters, getSortOrder } from '../Utilities/SessionStorage';
import SelectFilter from '../DataTable/datatable.selectfilter';
import RecipeDialog from '../Dialogs/RecipeDialog';
import { useLotStatuses } from '../Utilities/Hooks';

function LotListTable({ lots, tableFilters, tableName, autoResetPage }) {

    const { t } = useTranslation();
    const [showRecipeDialog, setShowRecipeDialog] = useState(false);
    const [selectedRecipe, setSelectedRecipe] = useState();
    const { lotStatuses } = useLotStatuses(tableFilters.includeGraded);

    const columns = [
        {
            Header: t('LotList.ColHead.BusinessUnit'),
            accessor: 'businessUnit',
            Filter: SelectFilter,
            filter: 'equals'
        },
        {
            Header: t('LotList.ColHead.LotNumber'),
            accessor: 'lotNumber',
            Cell: (props) => {
                return (
                    <>
                        <Link to={{ pathname: `/lotdetails/${props.value}` }}>{props.value}</Link>
                        {props.row.original.isBlocked && <FontAwesomeIcon icon={faLock} title={t('LotStatus.Blocked')} className="ms-1" />}
                    </>
                );
            },
            filter: 'fuzzyText'
        },
        {
            Header: t('LotList.ColHead.Status'),
            accessor: 'lotStatus',
            Filter: SelectFilter,
            filterTranslationPrefix:"LotStatus",
            filter: 'equals',
            options: lotStatuses,
            Cell: (props) => {
                return t('LotStatus.' + props.value);
            }
        },
        {
            Header: t('LotList.ColHead.Grade'),
            accessor: 'grade',
            filter: 'fuzzyText',
            Cell: (props) => {
                return <div className="clickable" onClick={() => {
                    setSelectedRecipe({ grade: props.value, businessUnitCode: props.row.original.businessUnit });
                    setShowRecipeDialog(true);
                }
                }>{props.value}</div>
            },
        },
        {
            Header: t('LotList.ColHead.Weight'),
            accessor: 'lotWeight',
            filter: 'NoFilter'
        },
        DateColumn({ header: t('LotList.ColHead.BuildStart'), accessor: 'buildStart' }),
        DateColumn({ header: t('LotList.ColHead.BuildEnd'), accessor: 'buildEnd' }),
        {
            Header: t('LotList.ColHead.LastModifiedBy'),
            accessor: 'lastModifiedBy',
            filter: 'fuzzyText'
        },
        {
            Header: t('LotList.ColHead.LastComment'),
            accessor: 'lastGradingComment',
            filter: 'fuzzyText'
        }
    ];

    const initialState = {
        pageSize: 20,
        sortBy: [
            {
                id: 'buildStart',
                desc: true
            }
        ]
    }

    const tableId = tableName ?? "lots-table";
    const sortBy = getSortOrder(tableId);
    const filters = getTableFilters(tableId);

    if (sortBy) {
        initialState.sortBy = sortBy;
    }

    if (filters) {
        initialState.filters = filters;
    }

    let noLotsMessage = "";
    if (tableFilters.status) {
        noLotsMessage = <div>No {!tableFilters.includeGraded ? "ungraded" : ""} lots with status <strong>{t('LotStatus.' + tableFilters.status)}</strong> to show</div>
    } else {
        noLotsMessage = <div>No {!tableFilters.includeGraded ? "ungraded" : ""} lots to show</div>;
    }

    return lots.length > 0
        ? <>
            <DataTable id={tableId} tableColumns={columns} tableData={lots} initialState={initialState} autoResetPage={autoResetPage} />
            {selectedRecipe &&
                <RecipeDialog showDialog={showRecipeDialog} closeDialog={() => setShowRecipeDialog(false)} name={selectedRecipe.grade} businessUnitCode={selectedRecipe.businessUnitCode} />
            }
        </>
        :
        <div className="d-flex flex-column mt-5">
            <h4 className="text-center">{noLotsMessage}</h4>
            <p className="text-center text-muted"><small>Check filters if lots are expected</small></p>
        </div>
}

export default LotListTable;