import React, { useContext } from "react";
import { Spinner } from "react-bootstrap";
import { Navigate } from "react-router-dom";

import PrivilegeContext from './Contexts/PrivilegeContext';


export type ProtectedRouteProps = {
    privilege: Privilege,
    route?:string
    children: React.ReactNode,
};

export type Privilege = "admin" | "supervisor" | "canProcess" | "canReview" | "canGrade";

const ProtectedRoute = ({ privilege, route, children }: ProtectedRouteProps) => {
    const { admin, supervisor, canProcess, canReview, canGrade } = useContext(PrivilegeContext);
    let hasPermission: boolean | undefined;

    switch (privilege) {
        case "admin":
            hasPermission = admin;
            break;
        case "supervisor":
            hasPermission = supervisor; 
            break;
        case "canProcess":
            hasPermission = canProcess; 
            break;
        case "canReview":
            hasPermission = canReview; 
            break;
        case "canGrade":
            hasPermission = canGrade; 
            break;
    }

    if (hasPermission === undefined) {
        return <Spinner animation={"border"}/>
    }

    if (!hasPermission) {
        return <Navigate to={route ?? "/"} />;
    }

    return children;
}

export default ProtectedRoute