import React from 'react';
import { Row } from 'react-table';

import { Quality, QualityValue } from '../../../../Types/Quality';
import GetQualityColumnClass from './GetQualityColumnClass';

export interface QualityCellProps {
    row: Row<Quality>,
    qualityType: string,
}

function QualityCell({ row, qualityType }: QualityCellProps): React.JSX.Element {
    let value = null;
    let className = "";

    row.original.qualityValues.forEach(function (qualityValue:QualityValue) {

        if (qualityValue.testMethod === qualityType) {
            value = qualityValue.resultValue;
            className = GetQualityColumnClass(qualityValue);
        }
    });

    return <div className={'quality-value-column ' + className}>
        {value}
    </div>;
}

export default QualityCell;