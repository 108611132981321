import { useContext, useState, useEffect } from 'react';
import { Accordion, Button, Col, Container, Form, Modal, Row, Spinner, ToggleButton, Nav } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


import useAlert from '../../Alerts/useAlert'
import PrivilegeContext from '../../Contexts/PrivilegeContext';
import DataTable from '../../DataTable/datatable.component';
import { RequiredDialogInput, RequiredDialogIntegerInput } from '../../DialogInputs';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import { handleInputChange } from '../../Utilities/DialogUtils';
import CommentDialog from '../CommentDialog';
import { RecipeToPackageTypeEditDialog } from './RecipeToPackageTypeEditDialog';

function PackageTypeArea({ packageType, setPackageType }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [fieldErrors, setFieldErrors] = useState({});
    const [validated, setValidated] = useState(true);
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const { setAlert } = useAlert();
    const { canConfigure } = useContext(PrivilegeContext);

    const onChange = (e) => {
        handleInputChange(e, packageType, setPackageType, fieldErrors, setFieldErrors);
    }

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        }

        setValidated(true);
    };

    const handleCommentSave = (comment) => {

        packageType.comment = comment

        savePackageType(packageType)
            .then(data => {
                alert("Package Type saved successfully");
                setFieldErrors({});
                setPackageType(data);
            })
            .catch(error => {
                setFieldErrors({
                    ...fieldErrors,
                    ...error.errors
                });

                for (const field in error.errors) {
                    let fieldDOM = document.querySelector("[name=" + field + "]");
                    fieldDOM.setCustomValidity(error.errors[field]);
                }
            });
    }

    const savePackageType = (packageType) => {
        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/packageType`;
            if (packageType.id != null) {
                url += `/${packageType.id}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(packageType))
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        response.text().then(error => {
                            console.log(error)
                            setAlert("danger", "Failed to delete", !!error ? error : "An unexpected error occured")
                        })
                    } else {
                        setAlert("success", "Updated", "Package Type has successfully been updated");
                    }
                    resolve(response);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
        });
        return promise;
    }

    const deletePackageType = (packageType) => {
        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/packageType/${packageType.id}`;

            fetchDataAuthenticated(url, "DELETE")
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        response.text().then(error => {
                            console.log(error)
                            setAlert("danger", "Failed to delete", !!error ? error : "An unexpected error occured")
                        })
                    } else {
                        setAlert("success", "Deleted", "Package Type has successfully been deleted");
                    }
                    resolve(response);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
        });
        return promise;
    }

    const handleDelete = (event) => {
        deletePackageType(packageType);
    }


    return (
        <>
            <Container>
                <Row>
                    <Form noValidate validated={validated} onSubmit={handleSave} id="packageTypeSummaryForm">
                        <fieldset disabled={!canConfigure}>
                            <RequiredDialogInput controlId="formPackageTypeName" title="Name" name="name" value={packageType.name} onChange={onChange} />
                            <RequiredDialogIntegerInput controlId="formPackageTypeUnitsPerPackage" title="Units Per Package" name="unitsPerPackage" value={packageType.unitsPerPackage} onChange={onChange} />
                            <RequiredDialogIntegerInput controlId="formPackageTypeUnitWeight" title="Unit Weight" name="unitWeight" value={packageType.unitWeight} onChange={onChange} />
                        </fieldset>
                    </Form>
                </Row>
                <Row>
                    <Col>
                        <Button variant="danger" className="float-start" type="button" disabled={!canConfigure} onClick={handleDelete}>
                            Delete
                        </Button>
                        <Button variant="primary" className="float-end" type="submit" form="packageTypeSummaryForm" disabled={!canConfigure}>
                            Save
                        </Button>
                    </Col>
                </Row>
            </Container>

            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleCommentSave} />
        </>
    );
}

function RecipeToPackageTypesTable({ onRowClick, packageTypeId, refresh }) {
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loading, setLoading] = useState();
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getRecipeToPackageTypes = function () {
            if (packageTypeId > 0) {
                setLoading(true);
                let url = `api/v1/recipeToPackageType/?packageTypeId=${packageTypeId}`;

                fetchDataAuthenticated(url)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong.');
                    })
                    .then(data => {
                        setTableData(data);
                        setErrored(false);
                    })
                    .catch(error => {
                        setErrored(true);
                    })
                    .finally(() => {
                        setLoading(false);
                    });
            }
        };

        getRecipeToPackageTypes();
    }, [packageTypeId, refresh]);

    let tableColumns = [
        {
            Header: 'Material Code',
            accessor: 'materialCode',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Recipe',
            accessor: 'recipeName',
            filter: 'includesString',
        },
        {
            Header: 'Package Type',
            accessor: 'packageTypeName',
            filter: 'includesString',
        },
        {
            Header: 'Is Default',
            accessor: 'isDefault',
            filter: 'includesString',
            Cell: (props) => <div>{props.value.toString()}</div>
        },
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    if (loading) {
        return <p><em>Loading...</em></p>
    }

    if (errored) {
        return <p>There was a problem</p>
    }

    if (tableData.length === 0) {
        return <></>
    }

    return <DataTable tableColumns={tableColumns} tableData={tableData} initialState={{ pageSize: 50 }} getRowProps={getRowProps} />;
}

function RecipeToPackageTypesArea({ packageType }) {
    const [refreshList, setRefreshList] = useState(0);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({ packageTypeId: packageType.id, recipeId: null, materialCode: "" });

    const { canConfigure } = useContext(PrivilegeContext);

    const showEditDialog = (dialogData) => {
        setDialogData(dialogData);
        setShowDialog(true);
    }

    const showNewDialog = () => {
        setDialogData({ packageTypeId: packageType.id, recipeId: null, materialCode: "" });
        setShowDialog(true);
    }

    const closeDialog = () => {
        setShowDialog(false);
        setRefreshList(refreshList + 1);
    };

    return (
        <Container fluid="md" className="mb-3">
            <Row>
                <Col>
                    <RecipeToPackageTypesTable onRowClick={showEditDialog} packageTypeId={packageType.id} refresh={refreshList} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button className="float-end" variant="primary" onClick={showNewDialog} disabled={!canConfigure}>Add</Button>
                </Col>
            </Row>

            <RecipeToPackageTypeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />

        </Container>
    );
}

export function PackageTypeEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {
    const [loading, setLoading] = useState(false);

    return (
        <Modal dialogAs={DraggableModalDialog} handle=".packageType-header" className="detail-dialog" show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton className="packageType-header">
                <Modal.Title>Package Type - {dialogData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading
                    ? <Spinner />
                    : <Accordion defaultActiveKey={['summary', 'recipeToPackageType']} alwaysOpen>
                        <Accordion.Item eventKey="summary">
                            <Accordion.Header>Package Type</Accordion.Header>
                            <Accordion.Body>
                                <PackageTypeArea packageType={dialogData} setPackageType={setDialogData} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="recipeToPackageType">
                            <Accordion.Header>Recipe to Package Type</Accordion.Header>
                            <Accordion.Body>
                                <RecipeToPackageTypesArea packageType={dialogData} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
}