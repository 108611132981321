import { createContext, useState } from 'react';
import { useHttpUtils } from '../Utilities/useHttpUtils';

const initialState = {
    readyToReviewCount: 0,
    readyToGradeCount: 0
};

const LotContext = createContext({
    ...initialState,
    update: () => { }
});

export const LotProvider = ({ children }) => {
    const [readyToReviewCount, setReadyToReviewCount] = useState(0);
    const [readyToGradeCount, setReadyToGradeCount] = useState(0);
    const { fetchDataAuthenticated } = useHttpUtils();

    const updateCounters = () => {
        updateReadyToReviewCount();
        updateReadyToGradeCount();
    };

    async function updateReadyToReviewCount() {
        await fetchDataAuthenticated('/api/v1/lots/count?status=readyToReview')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error();
            })
            .then(count => setReadyToReviewCount(count))
            .catch(() => console.error("Failed to retrieve number of lots readyToReview"));
    }

    async function updateReadyToGradeCount() {
        await fetchDataAuthenticated('/api/v1/lots/count?status=readyToGrade')
            .then(response => {
                if (response.ok) {
                    return response.json();
                }

                throw new Error();
            })
            .then(count => setReadyToGradeCount(count))
            .catch(() => console.error("Failed to retrieve number of lots readyToGrade"));
    }

    return (
        <LotContext.Provider value={{ readyToReviewCount, readyToGradeCount, updateCounters }}>
            {children}
        </LotContext.Provider>
    );
};

export default LotContext;