import React, { useState } from 'react';
import CommonConfigDialog from './CommonConfigDialog';
import { DialogInput, RequiredDialogSelect, DialogSelect, DialogCheckbox } from '../../DialogInputs';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useLines, useQualities, useCalcFunctions, useLabFunctions, useOffspecFunctions } from '../../Utilities/Hooks';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function LineQualityDataEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const [fieldErrors, setFieldErrors] = useState({});
    const lines = useLines(false, dialogData.businessUnitId);
    const qualities = useQualities(false, dialogData.businessUnitId);
    const calcFunctions = useCalcFunctions();
    const labFunctions = useLabFunctions();
    const offspecFunctions = useOffspecFunctions();

    const saveLineQualityData = (lineQualityData) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/linequalitydata`;
            if (lineQualityData.lineQualityDataId != null) {
                url += `/${lineQualityData.lineQualityDataId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(lineQualityData))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, dialogData, setDialogData, fieldErrors, setFieldErrors);
    }

    const renderFunctionSelect = (controlId, title, name, functions) => {
        return(
            <DialogSelect controlId={controlId} title={title} name={name} value={dialogData[name]} error={fieldErrors[name]} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {functions.map((func, index) => <option value={func} key={index}>{func}</option>)}
            </DialogSelect>
        );
    }

    return (
        <CommonConfigDialog title={"Line Quality Data"} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={saveLineQualityData} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} wideDialog={true}>
            <RequiredDialogSelect controlId="formLineQualityBusinessUnit" title="Business Unit" name="businessUnitId" value={dialogData.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </RequiredDialogSelect>
            <RequiredDialogSelect controlId="formLineQualityDataLine" title="Line" name="lineId" value={dialogData.lineId} error={fieldErrors.lineId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {lines.map((l) => <option value={l.lineId} key={l.lineId}>{l.name}</option>)}
            </RequiredDialogSelect>
            <RequiredDialogSelect controlId="formLineQualityDataQuality" title="Quality" name="qualityId" value={dialogData.qualityId} error={fieldErrors.qualityId} onChange={onChange}>
                <option value="" key={0} >Please Select...</option>
                {qualities.map((q) => <option value={q.qualityId} key={q.qualityId}>{q.name}</option>)}
            </RequiredDialogSelect>
            <DialogInput controlId="formLineQualityDefaultValue" title="Default Value To Use" name="defaultValueToUse" value={dialogData.defaultValueToUse} error={fieldErrors.defaultValueToUse} onChange={onChange}/>
            {renderFunctionSelect("formLineQualityTransferCalc", "Transfer Calc Func", "transferCalculateFunction", calcFunctions)}
            {renderFunctionSelect("formLineQualityTransferOffspec", "Transfer Offspec Func", "transferIsOffspecFunction", offspecFunctions)}
            {renderFunctionSelect("formLineQualityBatchCalc", "Batch Calc Func", "batchCalculateFunction", calcFunctions)}
            {renderFunctionSelect("formLineQualityBatchLabCalc", "Batch Lab Calc Func", "batchLabCalculateFunction", labFunctions)}
            {renderFunctionSelect("formLineQualityBatchOffspec", "Batch Offspec Func", "batchIsOffspecFunction", offspecFunctions)}
            {renderFunctionSelect("formLineQualityLotCalc", "Lot Calc Func", "lotCalculateFunction", calcFunctions)}
            {renderFunctionSelect("formLineQualityLotLabCalc", "Lot Lab Calc Func", "lotLabCalculateFunction", labFunctions)}
            {renderFunctionSelect("formLineQualityLotOffspec", "Lot Offspec Func", "lotIsOffspecFunction", offspecFunctions)}
            {renderFunctionSelect("formLineQualityPackoutLotCalc", "PO Lot Calc Func", "packoutLotCalculateFunction", calcFunctions)}
            {renderFunctionSelect("formLineQualityPackoutLotLabCalc", "PO Lot Lab Calc Func", "packoutLotLabCalculateFunction", labFunctions)}
            {renderFunctionSelect("formLineQualityPackoutLotOffspec", "PO Lot Offspec Func", "packoutLotIsOffspecFunction", offspecFunctions)}
            <DialogCheckbox disabled={dialogData.lineQualityDataId === null} controlId="formLineQualityIsDeleted" title="Deleted?" name="isDeleted" checked={dialogData.isDeleted} error={fieldErrors.isDeleted} onChange={onChange}/>
        </CommonConfigDialog>
    );
}

export default LineQualityDataEditDialog;