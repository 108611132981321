import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal } from 'react-bootstrap';
import { DraggableModalDialog } from './DraggableModalDialog';
import DataTable from '../DataTable/datatable.component';
import DateColumn from '../DataTable/datatable.datecolumn';
import NoFilter from '../DataTable/datatable.nofilter';

function SampleTable({ samples }) {

    const { t } = useTranslation();

    var tableData = samples;

    const tableColumns = [
        {
            Header: t('LabDetails.Samples.SampleId'),
            accessor: 'sampleID'
        },
        DateColumn({ header: t('LabDetails.Samples.SampleTime'), accessor: 'sampleTime' }),
        {
            Header: t('LabDetails.Samples.SamplePoint'),
            accessor: 'samplePoint'
        },
        {
            Header: t('LabDetails.Samples.Value'),
            accessor: 'value'
        },
        {
            Header: t('LabDetails.Samples.IsOffspec'),
            accessor: 'isOffspec',
            Filter: NoFilter,
            Cell: (props) => {
                return (props.value === true ? "Yes" : "No")
            }
        }
    ];

    const initialState = {
        sortBy: [
            {
                id: 'sampleTime',
                desc: false
            },
            {
                id: 'sampleID',
                desc: false
            }
        ]
    };

    var table = DataTable({ tableColumns, tableData, initialState });

    return table;
}

function LotDetailsDialog({ showDialog, closeDialog, qualityName, samples }) {

    const { t } = useTranslation();

    return (
        <Modal dialogAs={DraggableModalDialog} className="detail-dialog" show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t('LabDetails.Title') + " - " + qualityName}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <SampleTable samples={samples} />
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    {t("Application.Controls.Close")}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default LotDetailsDialog;