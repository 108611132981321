import React, { useState, useEffect } from 'react';
import DataTable from '../../DataTable/datatable.component';
import SelectFilter from '../../DataTable/datatable.selectfilter';
import RecipeTypeEditDialog from '../../Dialogs/Configuration/RecipeTypeEditDialog';
import { Nav, Form } from 'react-bootstrap';
import { useBusinessUnits } from '../../Utilities/Hooks';
import CommonConfigPage from './CommonConfigPage';
import { useHttpUtils } from '../../Utilities/useHttpUtils';

function RecipeTypesTable({ onRowClick, includeDeleted, businessUnitId, refresh }) {

    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getRecipeTypes = async function () {
            let url = `api/v1/recipetype/?includeDeleted=${includeDeleted}`;
            if (businessUnitId > 0)
                url += `&businessUnitId=${businessUnitId}`;

            await fetchDataAuthenticated(url)
                .then(response => {
                    if (response.ok) {
                        return response.json();
                    }
                    throw new Error('Something went wrong.');
                })
                .then(data => {
                    setTableData(data);
                    setErrored(false);
                    setLoaded(true);
                })
                .catch(error => {
                    setErrored(true);
                    setLoaded(false);
                });
        };

        getRecipeTypes();
    }, [includeDeleted, businessUnitId, refresh]);

    const tableColumns = [
        {
            Header: 'Name',
            accessor: 'name',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original); }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: 'Description',
            accessor: 'description',
            filter: 'includesString'
        },
        {
            Header: 'Business Unit',
            accessor: 'businessUnitName',
            Filter: SelectFilter,
            filter: 'equals'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: {} , getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
    : < p > <em>Loading...</em></p>;

    return tableMarkup;
}

function RecipeTypeConfiguration() {

    const businessUnits = useBusinessUnits(false);
    const [businessUnitId, setBusinessUnitId] = useState(0);
    
    const getNewRecipeType = () => {
        return { recipeTypeId: null, name: "", businessUnitId: businessUnitId, isDeleted: false }
    };

    return (
        <CommonConfigPage
            title="Recipe Types"
            table={(includeDeleted, showEditDialog, refreshList) => <RecipeTypesTable onRowClick={showEditDialog} includeDeleted={includeDeleted} businessUnitId={businessUnitId} refresh={refreshList} />}
            dialog={(dialogData, setDialogData, showDialog, closeDialog) => <RecipeTypeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnits={businessUnits} />}
            getNewConfigObject={getNewRecipeType}
        >
            <Form.Select type="text" value={businessUnitId} className="filter-select float-start" onChange={(e) => { setBusinessUnitId(e.currentTarget.value) }}>
                <option value={0} key={0}>All Business Units</option>
                {businessUnits.map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)}
            </Form.Select>
        </CommonConfigPage>
    );
}

export default RecipeTypeConfiguration;