import React, { useEffect, useState } from 'react';
import { Button, Modal, Accordion, Spinner } from 'react-bootstrap';
import { DraggableModalDialog } from './DraggableModalDialog';
import QualityGraph from './QualityGraph';
import { useTranslation } from 'react-i18next';
import { useHttpUtils } from '../Utilities/useHttpUtils';
import DataTable from '../DataTable/datatable.component';
import useAlert from '../Alerts/useAlert';

function LabResultsTable({ lotNumber, component }) {

    const [labValues, setLabValues] = useState([]);
    const [loading, setLoading] = useState(true);

    const { t } = useTranslation();
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();

    useEffect(() => {
        getLabValues();
    }, []);

    async function getLabValues() {
        await fetchDataAuthenticated(`/api/v1/Graph/labValues?lotNumber=${lotNumber}&qualityName=${component}`)
            .then(async response => {
                if (response.ok) {
                    return response.json();
                } else {
                    throw new Error(await response.text());
                }
            })
            .then(data => {
                setLabValues(data);
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            })
            .finally(() => {
                setLoading(false);
            });
    }

    var tableColumns = [
        {
            Header: t('LabValue.Identifier'),
            accessor: 'identifier',
            disableFilters: true
        },
        {
            Header: t('LabValue.SamplePoint'),
            accessor: 'samplePoint',
            disableFilters: true
        },
        {
            Header: t('LabValue.Value'),
            accessor: 'value',
            disableFilters: true
        },
        {
            Header: t('LabValue.SampleTime'),
            accessor: 'sampleTime',
            disableFilters: true
        }
    ];

    if (loading) {
        return (
            <div className="d-flex justify-content-center">
                <Spinner animation="border" />
            </div>
        );
    }

    return labValues.length > 0
        ? <DataTable tableColumns={tableColumns} tableData={labValues} />
        : t('CommonResponses.NoData');
}

function QualityGraphDialog({ showDialog, closeDialog, title, lotNumber, quality }) {

    const { t } = useTranslation();

    return (
        <Modal dialogClassName="modal-90w" dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <QualityGraph lotNumber={lotNumber} component={quality.component} />
                <Accordion defaultActiveKey={['labResults']} alwaysOpen>
                    <Accordion.Item eventKey="labResults">
                        <Accordion.Header>{t('LotDetails.LabResults.Title')}</Accordion.Header>
                        <Accordion.Body>
                            <LabResultsTable lotNumber={lotNumber} component={quality.component} />
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    {t('Application.Controls.Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default QualityGraphDialog;