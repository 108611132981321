import React, { useEffect, useState } from 'react';
import { Button } from 'react-bootstrap'
import { useTranslation } from 'react-i18next';

import useAlert from '../../../Alerts/useAlert';
import usePrivileges from '../../../Auth/usePrivileges';
import { useHttpUtils } from '../../../Utilities/useHttpUtils';
import DataTable from '../../../DataTable/datatable.component';
import NoFilter from '../../../DataTable/datatable.nofilter';
import CommentDialog from '../../../Dialogs/CommentDialog';
import ClassificationColumn from './QualtiesSection/ClassificationColumn';
import EditableQualityColumn from './QualtiesSection/EditableQualityColumn';
import LBMLabColumn from './QualtiesSection/LBMLabColumn';
import QualityColumn from './QualtiesSection/QualityColumn';
import QualityGraphGeneratorCell from './QualtiesSection/QualityGraphGeneratorCell';
import UsedQualityValueSelectCell from './QualtiesSection/UsedQualityValueSelectCell';

function QualitiesSection({ intialQualities, businessUnitCode, lotNumber, lotDetail, setLotDetail }) {
    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();
    const { canReview } = usePrivileges(businessUnitCode);
    const [hasChanges, setHasChanges] = useState(false);
    const [qualities, setQualities] = useState(JSON.parse(JSON.stringify(intialQualities)));
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const [modifiedRows, setModifiedRows] = useState([]);

    useEffect(() => {
        setQualities(JSON.parse(JSON.stringify(intialQualities)));
    }, [intialQualities]);

    useEffect(() => {
        let modifiedRows = [];
        for (let qualityIndex = 0; qualityIndex < qualities.length; qualityIndex++) {
            let orginalQuality = intialQualities[qualityIndex];
            let newQuality = qualities[qualityIndex];
            if (orginalQuality.qualityValues.length !== newQuality.qualityValues.length) {
                modifiedRows.push(qualityIndex);
            }
            for (let qualityValueIndex = 0; qualityValueIndex < newQuality.qualityValues.length; qualityValueIndex++) {
                let orginalQualityValue = orginalQuality.qualityValues[qualityValueIndex];
                let newQualityValue = newQuality.qualityValues[qualityValueIndex];

                if (newQualityValue.resultValue !== orginalQualityValue?.resultValue) {
                    modifiedRows.push(qualityIndex);
                }
                if (newQualityValue.status !== orginalQualityValue?.status) {
                    modifiedRows.push(qualityIndex);
                }
            }
        }

        setModifiedRows(modifiedRows);
        if (modifiedRows.length === 0) {
            setHasChanges(false);
        } else {
            setHasChanges(true);
        }
    }, [qualities]);

    const handleSaveWithComment = (comment) => {
        qualities[0].comment = comment

        fetchDataAuthenticated(`/api/v1/lot/${lotNumber}/qualities`, 'PUT', JSON.stringify(qualities))
            .then(response => {
                if (response.ok) {
                    setAlert("success", "Success", "Quality values updated");
                    setLotDetail({
                        ...lotDetail, qualities: qualities
                    });
                } else {
                    throw new Error('Failed to update quality values');
                }
            })
            .catch(error => {
                setAlert("danger", "Error", error.message);
            });
    }

    const handleUndo = () => {
        setQualities(JSON.parse(JSON.stringify(intialQualities)));
        setHasChanges(false);
    }

    var tableColumns = [
        {
            Header: t('LotDetails.Qualities.Name'),
            accessor: 'name',
            filter: 'includesString'
        },
        QualityColumn({ header: t('LotDetails.Qualities.Primary'), qualityType: 'ASADDED' }),
        {
            Header: t('LotDetails.Qualities.QualityValueConfidence'),
            accessor: 'confidence',
            Cell: (props) => {
                let result = props.row.original.qualityValues.find(qv => qv.testMethod === "ASADDED")
                return result ? result.status.substring(0, 2) : "";
            },
            Filter: NoFilter
        },
        QualityColumn({ header: t('LotDetails.Qualities.Secondary'), qualityType: 'SECONDARYASADDED' }),
        EditableQualityColumn({ header: t('LotDetails.Qualities.Manual'), qualityType: 'ALTERNATE', qualities, setQualities, canEdit:canReview }),
        QualityColumn({ header: t('LotDetails.Qualities.L3Lab'), qualityType: 'LABCALC' }),
        LBMLabColumn({ header: t('LotDetails.Qualities.LBMLab'), qualityType: 'LBMLABCALC' }),
        ClassificationColumn(),
        {
            Header: t('LotDetails.Qualities.Min'),
            accessor: 'min',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.Target'),
            accessor: 'target',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.Max'),
            accessor: 'max',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.Units'),
            accessor: 'units',
            Filter: NoFilter
        },
        {
            Header: t('LotDetails.Qualities.SelectUsedValue'),
            accessor: 'selectUsedValue',
            Filter: NoFilter,
            Cell: (props) => <UsedQualityValueSelectCell row={props.row} qualities={qualities} setQualities={setQualities} canEdit={canReview} />
        },
        {
            accessor: 'showGraphButton',
            Filter: NoFilter,
            Cell: (props) => {
                return (<QualityGraphGeneratorCell row={props.row} lotNumber={lotNumber} />)
            }
        },
        {
            accessor: 'displayOrder'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isOffspec)
            return { className: "offspec" };

        if (modifiedRows.includes(row.index))
            return { className: "bg-info" };

        return {};
    }; 

    const getCellProps = (cell) => {
        let className = "align-middle";
        if (cell.column.id === 'name') {
            className += " text-nowrap";
        }

        // If this is a cell in the classification column and the row (the quality) has an offspec classification flag set
        // then style the _cell_ as "offspec"
        if (cell.column.id === 'classificationOffspec' && cell.row.original.classificationOffspec) {
            className +=" offspec";
        }
        return { className: className };
    };

    const initialState = {
        hiddenColumns: ['displayOrder'],
        sortBy: [
            {
                id: 'displayOrder',
                desc: false
            }
        ],
        pageSize: 100
    };

    var table = DataTable({
        tableColumns: tableColumns, tableData: qualities, initialState: initialState, getRowProps: getRowProps, getCellProps: getCellProps, showPaging: false
    });

    return qualities.length > 0
        ? <>
            {table}
            <div className="d-flex">
                <Button className="ms-auto" variant="secondary" hidden={!hasChanges} onClick={handleUndo}>Undo</Button>
                <Button className="ms-2" hidden={!hasChanges} onClick={() => setShowCommentDialog(true)}>Save</Button>
            </div>
            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleSaveWithComment} />
        </>
        : t('CommonResponses.NoData');
}

export default QualitiesSection;