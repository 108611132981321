import React from "react";
import { Row, Col, Form, CloseButton, Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

import DateTimePicker from '../../Components/DateTimePicker';
import { DateToLocalDateJson } from '../../Utilities/DateUtils';

function LotSplitDateTimeBuilder({ lotSplitRows, setLotSplitRows, updatedSplit, currentGrade, alternativeGrades, allGrades }) {
    const { t } = useTranslation();

    const handleUpdateDateTime = (id, name, value) => {
        let e = {
            target: {
                name: name,
                value: DateToLocalDateJson(value),
                dataset: {
                    id: id
                }
            }
        }
        handleUpdateRow(e);
    }

    const handleUpdateRow = (e) => {
        let index = e.target.dataset.id;
        let prop = e.target.name;
        let value = e.target.value;

        let tempRows = [...lotSplitRows];
        let tempRow = { ...lotSplitRows[index] };

        tempRow[prop] = value;
        tempRows[index] = tempRow;

        setLotSplitRows(tempRows);
        updatedSplit();
    }

    // Add new empty row to the lot split list
    const addRow = () => {

        // increment suffix
        let lastSuffix = lotSplitRows[lotSplitRows.length - 1].suffix;
        let suffix = String.fromCharCode(lastSuffix.charCodeAt() + 1)

        const emptyRow = {
            startTime: lotSplitRows[lotSplitRows.length - 1].endTime,
            endTime: lotSplitRows[lotSplitRows.length - 1].endTime,
            suffix: suffix,
            grade: ""
        };

        let tempRows = [...lotSplitRows];
        tempRows.push(emptyRow);

        setLotSplitRows(tempRows);
        updatedSplit();
    }

    // Remove row at index from the lot split list
    const deleteRow = (index) => {
        let tempRows = [...lotSplitRows];
        tempRows.splice(index, 1);
        setLotSplitRows(tempRows);
        updatedSplit();
    }

    const LotSplitByDateTimeRow = (row, idx) => {
        let startTime = `startTime-${idx}`;
        let endTime = `endTime-${idx}`;
        let suffix = `suffix-${idx}`;
        let grade = `grade-${idx}`;
        let closeId = `closeId-${idx}`;

        return (
            <Row key={idx} className="mb-3">
                <Col sm={3}>
                    {idx === 0 &&
                        <p>{t("LotSplit.StartTime")}</p>
                    }
                    <Form.Group controlId={startTime}>
                        <DateTimePicker name="startTime" dateTime={row.startTime && new Date(row.startTime)} handleDateSelect={(date) => handleUpdateDateTime(idx, "startTime", date)} />
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    {idx === 0 &&
                        <p>{t("LotSplit.EndTime")}</p>
                    }
                    <Form.Group controlId={endTime}>
                        <DateTimePicker name="endTime" dateTime={row.endTime && new Date(row.endTime)} handleDateSelect={(date) => handleUpdateDateTime(idx, "endTime", date)} />
                    </Form.Group>
                </Col>
                <Col>
                    {idx === 0 &&
                        <p>{t("LotSplit.Suffix")}</p>
                    }
                    <Form.Group controlId={suffix}>
                        <Form.Control type="text" placeholder="Suffix"
                            name="suffix"
                            data-id={idx}
                            required
                            value={row.suffix}
                            maxLength={1}
                            onChange={handleUpdateRow}>
                        </Form.Control>
                    </Form.Group>
                </Col>
                <Col sm={3}>
                    {idx === 0 &&
                        <p>{t("LotSplit.Grade")}</p>
                    }
                    <Form.Group controlId={grade}>
                        <Form.Select
                            aria-label="Select grade"
                            name="grade"
                            data-id={idx}
                            required
                            value={row.grade}
                            onChange={handleUpdateRow}>
                            <option value="">{t("Application.Controls.DefaultSelect")}</option>
                            <option value={currentGrade}>{currentGrade}</option>
                            {alternativeGrades.map((grade, index) =>
                                <option key={`grade-${index}`} value={grade.grade}>{grade.grade}</option>
                            )}
                            <option value="" disabled>------------</option>
                            {allGrades.map((grade, index) =>
                                <option key={`grade-${alternativeGrades.length + 1 + index}`} value={grade}>{grade}</option>
                            )}
                        </Form.Select>
                    </Form.Group>
                </Col>
                <Col sm={1}>
                    {idx > 0 &&
                        <Form.Group controlId={closeId}>
                            {idx === 0 &&
                                <Form.Label style={{ visibility: "hidden" }}>Close</Form.Label>
                            }
                            <CloseButton onClick={() => deleteRow(idx)} />
                        </Form.Group>
                    }
                </Col>
            </Row>
        )
    }

    return (
        <>
            {
                lotSplitRows.map((row, idx) => {
                    return LotSplitByDateTimeRow(row, idx);
                })
            }
            <Button variant="primary" onClick={addRow}>{t("LotSplit.AddTimeRow")}</Button>
        </>
    );
}

export default LotSplitDateTimeBuilder