import { useContext, useState, useEffect } from 'react';
import ConfigurationContext from '../Contexts/ConfigurationContext';

const useLocale = () => {
    const configurationContext = useContext(ConfigurationContext);
    const [localeCode, setLocaleCode] = useState();
    let locale = configurationContext.configurationData.find(element => element.name === "Locale");

    useEffect(() => {
        if (locale?.value) {
            setLocaleCode(locale.value);
        }
    }, [locale])

    return localeCode;
}

export default useLocale