import { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import DataTable from '../../../DataTable/datatable.component';
import DateColumn from '../../../DataTable/datatable.datecolumn';
import { Link } from 'react-router-dom';
import ConfigurationContext from '../../../Contexts/ConfigurationContext';

function QIRSection_QIRMS({ qirs, showCreateQIRButton, businessUnit, lotNumber, line, grade }) {

    return (
        <>
            <QIRTable qirs={qirs} />
            {showCreateQIRButton &&
                <div className="d-flex justify-content-end">
                    <CreateQIRButton businessUnit={businessUnit} lotNumber={lotNumber} line={line} grade={grade} />
                </div>
            }
        </>
    );
}

function QIRTable({ qirs }) {

    const { t } = useTranslation();

    const tableColumns = [
        {
            Header: t('LotDetails.QIRs.IncidentId'),
            accessor: 'incidentId',
            filter: 'includesString',
            Cell: (props) => {
                let qir = props.row.original;

                if (qir.url !== undefined && qir.url !== "") {
                    return <Link to={qir.url} target="_blank">{props.value}</Link>
                }

                return props.value;
            }
        },
        {
            Header: t('LotDetails.QIRs.LotNumber'),
            accessor: 'lotNumber',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.QIRs.Status'),
            accessor: 'status',
            filter: 'includesString'
        },
        DateColumn({ header: t('LotDetails.QIRs.IncidentDate'), accessor: 'incidentDate' }),
        DateColumn({ header: t('LotDetails.QIRs.ReportedDate'), accessor: 'reportedDate' }),
        DateColumn({ header: t('LotDetails.QIRs.EndDate'), accessor: 'endDate' }),
        {
            Header: t('LotDetails.QIRs.Statement'),
            accessor: 'statement',
            filter: 'includesString'
        },
        {
            Header: t('LotDetails.QIRs.ReportedBy'),
            accessor: 'reportedBy',
            filter: 'includesString'
        }
    ];

    let table = DataTable({ tableColumns: tableColumns, tableData: qirs, initialState: {} });

    return qirs.length > 0 ? table : t('CommonResponses.NoData');
}

function CreateQIRButton({ businessUnit, lotNumber, line, grade }) {

    const { t } = useTranslation();

    const configurationContext = useContext(ConfigurationContext);

    let qirUrlConfig = configurationContext.configurationData.find(x => x.name === "QIRMS.CreateQIRUrl");
    if (qirUrlConfig) {

        const qirUrl = qirUrlConfig.value + `?businessUnit=${businessUnit}&lotNumber=${lotNumber}&line=${line}&grade=${grade}`;

        return (
            <Link to={qirUrl}>
                <Button>{t('LotDetails.QIRs.CreateQIR')}</Button>
            </Link>
        );
    }
    else {
        return <></>
    }
}

export default QIRSection_QIRMS;