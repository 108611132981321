import React, { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, ToggleButton, Modal, Accordion, Row, Col, Container, Nav, Form, Spinner } from 'react-bootstrap';

import DataTable from '../../DataTable/datatable.component';
import { DraggableModalDialog } from '../DraggableModalDialog';
import { handleInputChange } from '../../Utilities/DialogUtils';
import { useRecipeTypes, useEquipment, useRecipeToPackageTypes } from '../../Utilities/Hooks';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import CommentDialog from '../CommentDialog';
import RecipeDataEditDialog from './RecipeDataEditDialog';
import { RequiredSummaryInput, SummaryInput, RequiredSummarySelect, SummarySelect, SummaryCheckbox } from '../../DialogInputs';
import { useDateToLocaleString, longDateAndTimeFormat } from '../../Utilities/DateUtils';
import { sortByPropertyName } from '../../Utilities/SortFunctions';
import useAlert from '../../Alerts/useAlert';
import PrivilegeContext from '../../Contexts/PrivilegeContext';
import { RecipeToPackageTypeEditDialog } from './RecipeToPackageTypeEditDialog';

function RecipeSummaryArea({ recipe, setRecipe, businessUnits, refreshData, recipeToPackageTypes }) {

    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();
    const [validated, setValidated] = useState(true);
    const [fieldErrors, setFieldErrors] = useState({});
    const [showCommentDialog, setShowCommentDialog] = useState(false);
    const recipeTypes = useRecipeTypes(false, recipe.businessUnitId);
    const equipment = useEquipment(false, recipe.businessUnitId);

    useEffect(() => {
        refreshData();
    }, [recipe.recipeId]);

    const { canConfigure } = useContext(PrivilegeContext);

    const handleSave = (event) => {
        event.preventDefault();
        event.stopPropagation();

        const form = event.currentTarget;

        if (form.checkValidity() === true) {
            setShowCommentDialog(true);
        }

        setValidated(true);
    };

    const handleCommentSave = (comment) => {

        recipe.comment = comment

        saveRecipe(recipe)
            .then(data => {
                setAlert("success", "Saved", "Recipe saved successfully");
                setFieldErrors({});
                setRecipe(data);
                refreshData();
            })
            .catch(error => {
                setFieldErrors({
                    ...fieldErrors,
                    ...error.errors
                });

                for (const field in error.errors) {
                    let fieldDOM = document.querySelector("[name=" + field + "]");
                    fieldDOM.setCustomValidity(error.errors[field]);
                }
            });
    }

    const saveRecipe = (recipe) => {

        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/recipe`;
            if (recipe.recipeId != null) {
                url += `/${recipe.recipeId}`
            }

            fetchDataAuthenticated(url, "POST", JSON.stringify(recipe))
                .then(response => response.json())
                .then(data => {
                    if (data.errors !== null && data.errors !== undefined) {
                        reject(data);
                    }
                    resolve(data);
                })
                .catch(error => {
                    setAlert("danger", "Error", "There was a problem! " + error);
                });

        });

        return promise;
    };

    const onChange = (e) => {
        handleInputChange(e, recipe, setRecipe, fieldErrors, setFieldErrors);
    }

    return (
        <>
            <Container>
                <Row>
                    <Form noValidate validated={validated} onSubmit={handleSave} id="recipeSummaryForm">
                        <fieldset disabled={!canConfigure}>
                            <Form.Group as={Row} className="mb-1">
                                <RequiredSummaryInput title={t('Recipe.Summary.Name')} name="name" value={recipe.name} error={fieldErrors.name} onChange={onChange} />
                                <SummaryInput title={t('Recipe.Summary.PackoutGrade')} name="packoutGrade" value={recipe.packoutGrade} error={fieldErrors.packoutGrade} onChange={onChange} />
                                <RequiredSummarySelect title={t('Recipe.Summary.BusinessUnit')} name="businessUnitId" value={recipe.businessUnitId} error={fieldErrors.businessUnitId} onChange={onChange}>
                                    <option value="" key={0} >{t('Application.Controls.DefaultSelect')}</option>
                                    {businessUnits
                                        .sort(sortByPropertyName("name"))
                                        .map((bu) => <option value={bu.businessUnitId} key={bu.businessUnitId}>{bu.name}</option>)
                                    }
                                </RequiredSummarySelect>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <RequiredSummarySelect title={t('Recipe.Summary.RecipeType')} name="recipeTypeId" value={recipe.recipeTypeId} error={fieldErrors.recipeTypeId} onChange={onChange}>
                                    <option value="" key={0}>{t('Application.Controls.DefaultSelect')}</option>
                                    {recipeTypes
                                        .sort(sortByPropertyName("name"))
                                        .map((rt) => <option value={rt.recipeTypeId} key={rt.recipeTypeId}>{rt.name}</option>)
                                    }
                                </RequiredSummarySelect>
                                <SummarySelect title={t('Recipe.Summary.Equipment')} name="equipmentId" value={recipe.equipmentId} error={fieldErrors.equipmentId} onChange={onChange}>
                                    <option value="" key={0}>{t('Application.Controls.DefaultSelect')}</option>
                                    {equipment
                                        .sort(sortByPropertyName("name"))
                                        .map((e) => <option value={e.equipmentId} key={e.equipmentId}>{e.name}</option>)
                                    }
                                </SummarySelect>
                                <SummarySelect title={t('Recipe.Summary.DefaultPackageType')} name="defaultRecipeToPackageTypeId" value={recipe.defaultRecipeToPackageTypeId} error={fieldErrors.defaultRecipeToPackageTypeId} onChange={onChange}>
                                    <option value="" key={0}>{t('Application.Controls.DefaultSelect')}</option>
                                    {recipeToPackageTypes
                                        .sort(sortByPropertyName("materialCode"))
                                        .map(p => <option value={p.id} key={`package-type-${p.id}`}>{p.materialCode}</option>)
                                    }
                                </SummarySelect>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <RequiredSummaryInput title={t('Recipe.Summary.Version')} name="version" value={recipe.version} error={fieldErrors.version} onChange={onChange} />
                                <SummaryCheckbox title={t('Recipe.Summary.IsPrime')} name="isPrime" checked={recipe.isPrime} error={fieldErrors.isPrime} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryCheckbox title={t('Recipe.Summary.IsPellet')} name="isPellet" checked={recipe.isPellet} error={fieldErrors.isPellet} onChange={onChange} />
                                <SummaryInput title={t('Recipe.Summary.AddPack')} name="addpack" value={recipe.addpack} error={fieldErrors.addpack} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryInput title={t('Recipe.Summary.ReactorGrade')} name="reactorGrade" value={recipe.reactorGrade} error={fieldErrors.reactorGrade} onChange={onChange} />
                                <SummaryInput title={t('Recipe.Summary.GradeCode')} name="gradeCode" value={recipe.gradeCode} error={fieldErrors.gradeCode} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryInput title={t('Recipe.Summary.MaterialCode')} name="materialCode" value={recipe.materialCode} error={fieldErrors.materialCode} onChange={onChange} />
                                <SummaryInput title={t('Recipe.Summary.MaterialType')} name="materialType" value={recipe.materialType} error={fieldErrors.materialType} onChange={onChange} />
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <Form.Label column sm={2}>{t('Recipe.Summary.ModifiedBy')}</Form.Label>
                                <Col sm={4}>
                                    <Form.Control disabled type="text" value={recipe.lastChangeAccount} />
                                </Col>
                                <Form.Label column sm={2}>{t('Recipe.Summary.ModifiedDate')}</Form.Label>
                                <Col sm={4}>
                                    <Form.Control disabled type="text" value={useDateToLocaleString(recipe.lastChange, longDateAndTimeFormat)} />
                                </Col>
                            </Form.Group>

                            <Form.Group as={Row} className="mb-1">
                                <SummaryCheckbox disabled={recipe.recipeId === null} title={t('Recipe.Summary.IsDeleted')} name="isDeleted" checked={recipe.isDeleted} error={fieldErrors.isDeleted} onChange={onChange} />
                            </Form.Group>
                        </fieldset>
                    </Form>
                </Row>
                <Row>
                    <Col>
                        <Button variant="primary" className="float-end" type="submit" form="recipeSummaryForm" disabled={!canConfigure}>
                            {t('Application.Controls.Save')}
                        </Button>
                    </Col>
                </Row>
            </Container>

            <CommentDialog showDialog={showCommentDialog} closeDialog={() => setShowCommentDialog(false)} onSave={handleCommentSave} />
        </>
    );
}

function RecipeDataTable({ onRowClick, includeDeleted, recipeId, refresh }) {

    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();
    const [loaded, setLoaded] = useState(false);
    const [errored, setErrored] = useState(false);
    const [tableData, setTableData] = useState([]);

    useEffect(() => {
        const getRecipeData = async function () {
            if (recipeId > 0) {
                let url = `api/v1/recipedata/?includeDeleted=${includeDeleted}&recipeId=${recipeId}`;

                await fetchDataAuthenticated(url)
                    .then(response => {
                        if (response.ok) {
                            return response.json();
                        }
                        throw new Error('Something went wrong.');
                    })
                    .then(data => {
                        setTableData(data);
                        setErrored(false);
                        setLoaded(true);
                    })
                    .catch(error => {
                        setErrored(true);
                        setLoaded(false);
                    });
            }
        };

        getRecipeData();
    }, [includeDeleted, recipeId, refresh]);

    const tableColumns = [
        {
            Header: t('Recipe.RecipeData.Quality'),
            accessor: 'qualityName',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.Analysis'),
            accessor: 'analysis',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.Component'),
            accessor: 'component',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.Property'),
            accessor: 'recipePropertyName',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original) }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: t('Recipe.RecipeData.DataType'),
            accessor: 'recipeDataTypeName',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.Units'),
            accessor: 'units',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.DecimalPlaces'),
            accessor: 'decimalPlaces',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.MinValue'),
            accessor: 'minValue',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.TargetValue'),
            accessor: 'targetValue',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.MaxValue'),
            accessor: 'maxValue',
            filter: 'includesString'
        },
        {
            Header: t('Recipe.RecipeData.DisplayOrder'),
            accessor: 'displayOrder',
            filter: 'includesString'
        }
    ];

    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    var table = DataTable({ tableColumns: tableColumns, tableData: tableData, initialState: { pageSize: 50 }, getRowProps: getRowProps });

    let tableMarkup = loaded
        ? table
        : errored ? <p>There was a problem.</p>
            : <p> <em>Loading...</em></p>;

    return tableMarkup;
}

function RecipeDataArea({ recipe, refreshToken, refreshData }) {

    const { t } = useTranslation();
    const [includeDeleted, setIncludeDeleted] = useState(false);
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({ name: "" });

    const { canConfigure } = useContext(PrivilegeContext);

    const showEditDialog = (dialogData) => {
        setDialogData(dialogData);
        setShowDialog(true);
    }

    const showNewDialog = () => {
        setDialogData({ recipeDataId: null, recipeId: recipe.recipeId, recipeName: recipe.name });
        setShowDialog(true);
    }

    const closeDialog = () => {
        setShowDialog(false);
        refreshData();
    };

    return (
        <>
            <Container fluid="md" className="mb-3">
                <Row>
                    <Col>
                        <RecipeDataTable onRowClick={showEditDialog} includeDeleted={includeDeleted} recipeId={recipe.recipeId} refresh={refreshToken} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <ToggleButton
                            className="mb-2 float-start"
                            id="toggle-check-deleted"
                            type="checkbox"
                            variant="outline-primary"
                            checked={includeDeleted}
                            value="1"
                            onChange={(e) => setIncludeDeleted(e.currentTarget.checked)}
                        >
                            {t('Recipe.RecipeData.IncludeDeleted')}
                        </ToggleButton>
                        <Button className="float-end" variant="primary" onClick={showNewDialog} disabled={!canConfigure}>{t('Application.Controls.Add')}</Button>
                    </Col>
                </Row>
            </Container>

            <RecipeDataEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} businessUnitId={recipe.businessUnitId} />
        </>
    );
}

function RecipeToPackageTypesTable({ onRowClick, refresh, recipeToPackageTypes }) {
    const { t } = useTranslation();
    let tableColumns = [
        {
            Header: t('Recipe.RecipeToPackageType.MaterialCode'),
            accessor: 'materialCode',
            filter: 'includesString',
            Cell: (props) => {
                return (<Nav><Nav.Link onClick={() => { onRowClick(props.row.original) }}>{props.value}</Nav.Link></Nav>)
            }
        },
        {
            Header: t('Recipe.RecipeToPackageType.Recipe'),
            accessor: 'recipeName',
            filter: 'includesString',
        },
        {
            Header: t('Recipe.RecipeToPackageType.PackageType'),
            accessor: 'packageTypeName',
            filter: 'includesString',
        },
        {
            Header: t('Recipe.RecipeToPackageType.IsDefault'),
            accessor: 'isDefault',
            filter: 'includesString',
            Cell: (props) => <div>{props.value ? "Yes" : ""}</div>
        },
    ];
    const getRowProps = (row) => {
        if (row.original.isDeleted)
            return { className: "deleted" };

        return {};
    };

    let table = DataTable({ tableColumns: tableColumns, tableData: recipeToPackageTypes, initialState: { pageSize: 50 }, getRowProps: getRowProps });

    return table;
}

function RecipeToPackageTypesArea({ recipe, refreshToken, refreshData, recipeToPackageTypes }) {
    const { t } = useTranslation();
    const [showDialog, setShowDialog] = useState(false);
    const [dialogData, setDialogData] = useState({ recipeId: recipe.recipeId, packageTypeId: null, materialCode: "" });

    const { canConfigure } = useContext(PrivilegeContext);

    const showEditDialog = (dialogData) => {
        setDialogData(dialogData);
        setShowDialog(true);
    }

    const showNewDialog = () => {
        setDialogData({ recipeId: recipe.recipeId, packageTypeId: null, materialCode: "" });
        setShowDialog(true);
    }

    const closeDialog = () => {
        setShowDialog(false);
        refreshData();
    };

    return (
        <>
            <Container fluid="md" className="mb-3">
                <Row>
                    <Col>
                        <RecipeToPackageTypesTable onRowClick={showEditDialog} refresh={refreshToken} recipeToPackageTypes={recipeToPackageTypes} />
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className="float-end" variant="primary" onClick={showNewDialog} disabled={!canConfigure}>{t('Application.Controls.Add')}</Button>
                    </Col>
                </Row>
            </Container>
            <RecipeToPackageTypeEditDialog dialogData={dialogData} setDialogData={setDialogData} showDialog={showDialog} closeDialog={closeDialog} />
        </>
    );
}

function RecipeEditDialog({ dialogData, setDialogData, showDialog, closeDialog, businessUnits }) {

    const { t } = useTranslation();
    const { fetchDataAuthenticated } = useHttpUtils();
    const { setAlert } = useAlert();
    const { canConfigure } = useContext(PrivilegeContext);
    const [loading, setLoading] = useState(false);
    const [refreshToken, setRefreshToken] = useState(0);
    const recipeToPackageTypes = useRecipeToPackageTypes(dialogData.recipeId, refreshToken);

    const refresh = () => {
        setRefreshToken(r => r + 1);
    }

    const cloneRecipe = async () => {
        setLoading(true);
        let url = `api/v1/recipe/${dialogData.recipeId}/clone`;

        await fetchDataAuthenticated(url, "POST")
            .then(response => {
                if (response.ok) {
                    return response.json();
                }
                throw new Error('Something went wrong.');
            })
            .then(data => {
                setDialogData({ ...data });
                setLoading(false);
                setAlert("success", "Copied", "Recipe has been copied");
            });
    };

    return (
        <Modal dialogAs={DraggableModalDialog} handle=".recipe-header" className="detail-dialog tall-dialog" show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton className="recipe-header">
                <Modal.Title>{t('Recipe.Title')} - {dialogData.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {loading
                    ? <Spinner />
                    : <Accordion defaultActiveKey={['summary', 'recipeData', 'recipeToPackageType']} alwaysOpen>
                        <Accordion.Item eventKey="summary">
                            <Accordion.Header>{t('Recipe.Summary.Title')}</Accordion.Header>
                            <Accordion.Body>
                                <RecipeSummaryArea recipe={dialogData} setRecipe={setDialogData} businessUnits={businessUnits} refreshData={refresh} recipeToPackageTypes={recipeToPackageTypes} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="recipeData">
                            <Accordion.Header>{t('Recipe.RecipeData.Title')}</Accordion.Header>
                            <Accordion.Body>
                                <RecipeDataArea recipe={dialogData} refreshToken={refreshToken} refreshData={refresh} />
                            </Accordion.Body>
                        </Accordion.Item>
                        <Accordion.Item eventKey="recipeToPackageType">
                            <Accordion.Header>{t('Recipe.RecipeToPackageType.Title')}</Accordion.Header>
                            <Accordion.Body>
                                <RecipeToPackageTypesArea recipe={dialogData} refreshToken={refreshToken} refreshData={refresh} recipeToPackageTypes={recipeToPackageTypes} />
                            </Accordion.Body>
                        </Accordion.Item>
                    </Accordion>
                }
            </Modal.Body>
            <Modal.Footer>
                {canConfigure &&
                    <Button className="me-auto" variant="secondary" onClick={cloneRecipe}>
                        {t('Recipe.CloneRecipe')}
                    </Button>
                }
                <Button variant="secondary" onClick={closeDialog}>
                    {t('Application.Controls.Close')}
                </Button>
            </Modal.Footer>
        </Modal>
    );
}

export default RecipeEditDialog;