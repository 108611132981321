import React, { useState } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { DraggableModalDialog } from './DraggableModalDialog';

function CommentDialog({ showDialog, closeDialog, onSave }) {

    const { t } = useTranslation();
    const [comment, setComment] = useState("");

    const onChange = (e) => {
        setComment(e.currentTarget.value);
    }

    const handleSave = (e) => {
        e.preventDefault();
        e.stopPropagation();

        onSave(comment);
        closeDialog();
    }
    
    return (
        <Modal dialogAs={DraggableModalDialog} show={showDialog} onHide={closeDialog} backdrop="static">
            <Modal.Header closeButton>
                <Modal.Title>{t("Dialogs.Comment.Title")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSave} id="commentForm">
                    <Form.Control as="textarea" rows="6" value={comment} onChange={onChange} />
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={closeDialog}>
                    {t("Application.Controls.Close")}
                </Button>
                <Button variant="primary" type="submit" form="commentForm">
                    {t("Application.Controls.Save")}
                </Button>
            </Modal.Footer>
        </Modal>

    );
}

export default CommentDialog;