import React from 'react';
import { useState, useEffect } from 'react';
import { Form, Row, Col } from 'react-bootstrap';
import { useAsyncDebounce } from 'react-table';
import { useTranslation } from "react-i18next";

import DateTimePicker from './Components/DateTimePicker';
import { DateToLocalDateJson } from './Utilities/DateUtils'

function DateRange({ dateFilter, setDateFilter }) {
    const { t } = useTranslation();
    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    useEffect(() => {
        handleChange();
    }, [fromDate, toDate]);

    const handleChange = useAsyncDebounce(() => {
        dateFilter.from = fromDate ? DateToLocalDateJson(fromDate): null
        dateFilter.to = toDate ? DateToLocalDateJson(toDate) : null;
        setDateFilter(prevState => {
            return { ...prevState, ...dateFilter }
        });
    }, 500);

    return (
        <Row>
            <Col xs={12} xl>
                <Form.Group as={Row} controlId="dateFrom">
                    <Form.Label column xs={2} sm={4}>{t("Filter.DateFrom")}:</Form.Label>
                    <Col xs={10} sm={8}>
                        <DateTimePicker name="dateFrom" dateTime={fromDate} handleDateSelect={(date)=>setFromDate(date)} dateOnly isClearable />
                    </Col>
                </Form.Group>
            </Col>
            <Col xs={12} xl>
                <Form.Group as={Row} controlId="dateTo">
                    <Form.Label column xs={2} sm={4}>{t("Filter.DateTo")}:</Form.Label>
                    <Col xs={10} sm={8}>
                        <DateTimePicker name="dateTo" dateTime={toDate} handleDateSelect={(date) => setToDate(date)} dateOnly isClearable />
                    </Col>
                </Form.Group>
            </Col>
        </Row>
            
    );
}

export default DateRange;