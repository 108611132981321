import React, { useEffect, useMemo } from 'react';
import { Form } from 'react-bootstrap';
import { useTable, usePagination, useSortBy, useFilters, useGlobalFilter } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSortDown, faSortUp, faAnglesLeft, faAngleLeft, faAngleRight, faAnglesRight } from "@fortawesome/free-solid-svg-icons";
import DefaultColumnFilter from './datatable.columnfilter';
import BTable from 'react-bootstrap/Table';
import { persistSortOrder, persistTableFilters } from '../Utilities/SessionStorage';
import { useTranslationUtils } from '../Utilities/TranslationUtils';

// Create a default prop getter
const defaultPropGetter = () => ({})

function DataTable({ id = Math.random(), tableColumns, tableData, initialState, getRowProps = defaultPropGetter, getCellProps = defaultPropGetter, showPaging = true, autoResetPage}) {

    const { tp } = useTranslationUtils();

    const columns = useMemo(() => tableColumns, [tableColumns]);
    const data = useMemo(() => tableData, [tableData]);

    const {
        page,
        getTableProps,
        getTableBodyProps,
        headerGroups,
        prepareRow,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        state: { pageIndex, pageSize, sortBy, filters },
    } = useTable(
        {
            columns,
            data,
            initialState: initialState,
            defaultColumn: {
                Filter: DefaultColumnFilter,
            },
            // When these following properties are to set true, the data needs to be memoized
            autoResetFilters: false,
            autoResetGlobalFilter: false,
            autoResetHiddenColumns: false,
            autoResetSortBy: false,
            autoResetPage: autoResetPage?.current ?? true
        },
        useFilters,
        useGlobalFilter,
        useSortBy,
        usePagination
    );

    useEffect(() => {
        persistSortOrder(id, sortBy);
    }, [sortBy])

    useEffect(() => {
        persistTableFilters(id, filters);
    }, [filters])

    function Pagination() {
        return (
            <div className="d-flex justify-content-end align-items-center">
                <div className="me-3">
                    <Form.Select
                        id="pageSize"
                        size="sm"
                        value={pageSize}
                        onChange={e => {
                            setPageSize(Number(e.target.value))
                        }}>
                        {[10, 20, 30, 40, 50].map(pageSize => (
                            <option key={pageSize} value={pageSize}>
                                {tp("Pagination.ShowXRows", { x: pageSize })}
                            </option>
                        ))}
                    </Form.Select>
                </div>
                <span className="align-items-center me-3">{tp("Pagination.PageXofY", { x: pageIndex + 1, y: pageOptions.length })}</span>
                <div className="pagination">
                    <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                        <FontAwesomeIcon icon={faAnglesLeft} />
                    </button>
                    <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                        <FontAwesomeIcon icon={faAngleLeft} />
                    </button>
                    <button onClick={() => nextPage()} disabled={!canNextPage}>
                        <FontAwesomeIcon icon={faAngleRight} />
                    </button>
                    <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                        <FontAwesomeIcon icon={faAnglesRight} />
                    </button>
                </div>
            </div>
        )
    }

    return (
        <>
            <BTable responsive striped bordered hover size="sm" {...getTableProps()}>
                <thead>
                    {headerGroups.map(headerGroup => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map(column => (
                                <th {...column.getHeaderProps()}>
                                    <div className="disable-select" {...column.getHeaderProps([column.getSortByToggleProps(), { className: column.className }])}>
                                        <span className="float-end">
                                            {column.isSorted ? column.isSortedDesc ? <FontAwesomeIcon icon={faSortDown} /> : <FontAwesomeIcon icon={faSortUp} /> : <div className="invisible"><FontAwesomeIcon icon={faSortUp} /></div>}
                                        </span>
                                        {column.render('Header')}
                                    </div>
                                    <div>{column.canFilter ? column.render('Filter') : null}</div>
                                </th>
                            ))}
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                        prepareRow(row)
                        return (
                            <tr {...row.getRowProps(getRowProps(row))}>
                                {row.cells.map(cell => {
                                    return (
                                        <td
                                            {...cell.getCellProps(getCellProps(cell))}
                                        >
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                })}
                            </tr>
                        )
                    })}
                </tbody>
            </BTable>

            {showPaging &&
                <Pagination />
            }
        </>
    )
}

export default DataTable;