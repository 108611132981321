import { useState, useEffect } from 'react';
import { DialogInput } from '../../DialogInputs';
import { useDateToLocaleString, longDateAndTimeFormat } from '../../Utilities/DateUtils';
import { useHttpUtils } from '../../Utilities/useHttpUtils';
import { Accordion, Spinner } from 'react-bootstrap';
import { usePrivileges } from '../../Auth/usePrivileges'
import CommonConfigDialog from './CommonConfigDialog';
import DOMPurify from 'dompurify';
import useAlert from '../../Alerts/useAlert' 
import '../Dialog.css'

function EmailEditDialog({ dialogData, setDialogData, showDialog, closeDialog }) {
    const [loading, setloading] = useState(false);
    const [emailContent, setEmailContent] = useState(null);
    const [fieldErrors, setFieldErrors] = useState({});
    const { admin, supervisor } = usePrivileges();
    const { setAlert } = useAlert();
    const { fetchDataAuthenticated } = useHttpUtils();

    useEffect(() => {
        setEmailContent(null);
    },[dialogData])

    const resendEmail = (email) => {
        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/email/${email.id}/resend`;

            fetchDataAuthenticated(url, "POST")
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        response.text().then(error => {
                            console.log(error)
                            setAlert("danger", "Failed to resend", !!error ? error : "An unexpected error occured")
                        })
                    } else {
                        setAlert("success", "Resent", "Email has successfully been queued to be resent");
                    }
                    resolve(response);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
        });
        return promise;
    };

    const getEmailContent = async (id) => {
        if (emailContent !== null) {
            return;
        }
        let url = `api/v1/email/${id}/content`;
        setloading(true);
        await fetchDataAuthenticated(url, "GET")
            .then(response => response.json())
            .then(data => {
                if (!!data.errors) {
                    return;
                }
                let cleanHtml = DOMPurify.sanitize(data)
                setEmailContent(cleanHtml);
            })
            .catch(error => {                                                
                alert("Fail to  fetch email content");
            })
            .finally(_ => {
                setloading(false)
            })
    }

    const deleteEmail = (email) =>
    {
        let promise = new Promise((resolve, reject) => {
            let url = `api/v1/email/${email.id}/delete`;

            fetchDataAuthenticated(url, "DELETE")
                .then(response => {
                    if (!response.ok) {
                        reject(response);
                        response.text().then(error => {
                            console.log(error)
                            setAlert("danger", "Failed to delete", !!error ? error : "An unexpected error occured")
                        })
                    } else {
                        setAlert("success", "Deleted", "Email has successfully been deleted");
                    }
                    resolve(response);
                })
                .catch(error => {
                    alert("There was a problem! " + error);
                });
        });
        return promise;
    }

    return (
        <CommonConfigDialog title={"Email - " + dialogData.subject} dialogData={dialogData} showDialog={showDialog} closeDialog={closeDialog} save={(admin || supervisor) && resendEmail} fieldErrors={fieldErrors} setFieldErrors={setFieldErrors} saveTranslationKey={"Configuration.Dialogs.Email.Resend"} wideDialog canDelete deleteFunction={deleteEmail}>
            <DialogInput controlId="formEmailTo" title="To" name="to" value={dialogData.to} readonly />
            <DialogInput controlId="formEmailSubject" title="Subject" name="subject" value={dialogData.subject} readonly />
            <DialogInput controlId="formEmailGeneratedAt" title="Generated At" name="generatedAt" value={useDateToLocaleString(dialogData.generatedAtLocalTime, longDateAndTimeFormat) } readonly />
            <DialogInput controlId="formEmailLastSentAt" title="Last Sent At" name="lastSentAt" value={useDateToLocaleString(dialogData.sentAtLocalTime, longDateAndTimeFormat)} placeholder={ "Not sent yet" } readonly />
            <DialogInput controlId="formEmailSendAttempts" title="Send Attempts" name="sendAttempts" value={dialogData.sendAttempts} readonly />
            <Accordion className="pt-1">
                <Accordion.Item id={"email-content-accordian"} eventKey={"content"}>
                    <Accordion.Header>
                        Email Content
                    </Accordion.Header>
                    <Accordion.Body onEnter={ ()=>getEmailContent(dialogData.id) }>
                        {loading
                            ? <div className="d-flex justify-content-center mb-3">
                                <Spinner animation="border" />
                            </div>
                            : <div className="email-content" dangerouslySetInnerHTML={{ __html: emailContent }}>
                            </div>
                        }
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </CommonConfigDialog> 
    );
}

export default EmailEditDialog;