import React, { useState } from 'react';
import { Row } from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine } from '@fortawesome/free-solid-svg-icons';

import QualityGraphDialog from '../../../../Dialogs/QualityGraphDialog';
import { Quality } from '../../../../Types/Quality';

export interface QualityGraphGeneratorCellProps {
    row: Row<Quality>,
    lotNumber: string,
}

function QualityGraphGeneratorCell({ row, lotNumber }: QualityGraphGeneratorCellProps): React.JSX.Element {
    const [showQualityGraph, setShowQualityGraph] = useState(false);

    return (
        <>
            <FontAwesomeIcon className="link" icon={faChartLine} onClick={() => setShowQualityGraph(true)} />
            {showQualityGraph &&
                <QualityGraphDialog showDialog={showQualityGraph} closeDialog={() => setShowQualityGraph(false)} title={lotNumber + " - " + row.original.component} lotNumber={lotNumber} quality={row.original} />
            }
        </>
    );
}

export default QualityGraphGeneratorCell;